import React, {useState} from "react";
import "./styles/ColourRange.scss";
import classNames from "classnames/bind";
import silvertexColors from "./data/silvertex";
import valenciaColors from "./data/valencia";
import ColoursTab from "components/ColourRange/components/ColoursTab";
import {FormattedMessage} from "react-intl";

export default function ColourRange() {
  const [tab, setTab] = useState("silvertex");

  return (
      <section className="colour-range mb-5">
        <ul className="nav nav-tabs mb-4">
          <li className="nav-item">
            <button
                className={classNames({
                  "nav-link": true,
                  "active": tab === "silvertex"
                })}
                onClick={() => setTab("silvertex")}>
              Silvertex<sup>®</sup> B1
            </button>
          </li>
          <li className="nav-item">
            <button
                className={classNames({
                  "nav-link": true,
                  "active": tab === "valencia"
                })}
                onClick={() => setTab("valencia")}>
              Valencia™ B1
            </button>
          </li>
        </ul>
        <div className="colour-range__tabs">
          {
            tab === "silvertex"
                ?
                <ColoursTab
                    title={<FormattedMessage
                        id="ColourRange.titleV2"
                        description="Title for ColourRange component"
                        defaultMessage="Colour Range Silvertex B1"/>}
                    subtitle={<FormattedMessage
                        id="ColourRange.subtitleV2"
                        description="Subtitle for ColourRange component"
                        defaultMessage="Dans chaque texture, une palette de couleurs est composée de {amount} couleurs délicates, parfaitement sélectionnées pour se combiner et se compléter. Des blancs délicats aux noirs en passant par les pastels,  les tons frais, la collection M1/B1 propose une vaste gamme de couleurs parfaite pour couvrir les concepts de mobilier. sophistiquées et normés: Cinéma, Gares, Aéroports, milieu médical, amphithéâtre, etc "
                        values={{
                          amount: silvertexColors.length
                        }}/>}
                    colors={silvertexColors}/>
                :
                <ColoursTab
                    title={<FormattedMessage
                        id="ColourRange.titleV3"
                        description="Title for ColourRange component"
                        defaultMessage="Colour Range Valencia B1"/>}
                    subtitle={<FormattedMessage
                        id="ColourRange.subtitleV2"
                        description="Subtitle for ColourRange component"
                        defaultMessage="Dans chaque texture, une palette de couleurs est composée de {amount} couleurs délicates, parfaitement sélectionnées pour se combiner et se compléter. Des blancs délicats aux noirs en passant par les pastels,  les tons frais, la collection M1/B1 propose une vaste gamme de couleurs parfaite pour couvrir les concepts de mobilier. sophistiquées et normés: Cinéma, Gares, Aéroports, milieu médical, amphithéâtre, etc "
                        values={{
                          amount: valenciaColors.length
                        }}/>}
                    colors={valenciaColors}/>
          }
        </div>
      </section>
  )
}