import React from "react";
import PropTypes from "prop-types";
import "./styles/ColourCard.scss";

const ColourCard = ({product, onClick}) => (
    <div
        className="colour-card"
        onClick={onClick}>
      <div
          className="colour-card__image"
          style={{
            backgroundImage: `url(${product.imageThumbSmall})`
          }}>
        <i className="fa fa-plus-circle"/>
      </div>
      <div className="colour-card__description">
        <h6>
          {
            product.name
          }
        </h6>
        <p>
          {
            product.code
          }
        </p>
      </div>
    </div>
);

ColourCard.propTypes = {
  product: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired
};

export default ColourCard;