import React from "react";
import logo from "./img/spradling-logo-landscape.svg";
import "./styles/Header.scss";

const Header = (props) => (
    <header className="maglia-header">
      <div className="container">
        <img src={logo} alt="Spradling Group" className="maglia-header-logo"/>
      </div>
    </header>
);

export default Header;