import React from "react";
import PropTypes from "prop-types";
import "./styles/SpecsCard.scss";

const SpecsCard = ({title, description, backgroundImage}) => (
    <div className="specs-card">
      <div
          className="specs-card__image"
          style={{
            backgroundImage: `url("${backgroundImage}")`
          }}/>
      <h2 className="specs-card__title text-center text-uppercase">
        {title}
      </h2>
      <p className="specs-card__description">
        {description}
      </p>
    </div>
);

SpecsCard.propTypes = {
  title: PropTypes.node.isRequired,
  description: PropTypes.node.isRequired,
  backgroundImage: PropTypes.string.isRequired
};

export default SpecsCard;