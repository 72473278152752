import React from "react";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import HomePage from "scenes/HomePage";
import RedirectToLanguage from "app/components/RedirectToLanguage";
import fr from 'react-intl/locale-data/fr';
import de from 'react-intl/locale-data/de';
import en from 'react-intl/locale-data/en';
import {addLocaleData} from "react-intl";

addLocaleData([...fr, ...de, ...en]);

const LandingMagliaApp = (props) => (
    <Router>
      <Switch>
        <Route path="/:lang([a-z]{2})/" component={HomePage}/>
        <Route component={RedirectToLanguage}/>
      </Switch>
    </Router>
);

export default LandingMagliaApp;