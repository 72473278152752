import React, {Component} from "react";
import {FormattedMessage} from "react-intl";
import {Mutation} from "react-apollo";
import b1CollectionSubscribeToNewsletterMutation from "components/Footer/components/NewsletterForm/mutation";


class NewsletterForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      loading: false,
      success: false
    };

    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(event) {
    this.setState({
      email: event.target.value
    })
  }

  render() {
    const {email, loading, success} = this.state;

    return (
        <Mutation mutation={b1CollectionSubscribeToNewsletterMutation}>
          {
            (subscribe, {data}) => (
                <form
                    className="footer-newsletter__form"
                    onSubmit={event => {
                      event.preventDefault();

                      this.setState({
                        loading: true
                      });

                      subscribe({
                        variables: {
                          input: {
                            email: email
                          }
                        }
                      }).then(() => {
                        this.setState({
                          email: '',
                          loading: false,
                          success: true
                        })
                      })
                    }}>
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">
                      <FormattedMessage
                          id="Footer.emailField"
                          description="Email field label in Footer"
                          defaultMessage="Email address"/>
                    </label>
                    <FormattedMessage
                        id="Footer.emailFieldPlaceholder"
                        description="Email field placeholder in Footer"
                        defaultMessage="Enter email">
                      {
                        placeholder =>
                            <input
                                type="email"
                                className="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                value={email}
                                required
                                onChange={this.handleInputChange}
                                placeholder={placeholder}/>
                      }
                    </FormattedMessage>
                  </div>
                  <button type="submit" className="btn btn-primary" disabled={loading}>
                    {
                      loading ?
                          <FormattedMessage
                              id="Footer.subscribeButtonLoadingLabel"
                              description="Subscribe button label in footer"
                              defaultMessage="Subscribing..."/>
                          : (
                              success ?
                                  <FormattedMessage
                                      id="Footer.subscribeButtonSuccessLabel"
                                      description="Subscribe button label in footer"
                                      defaultMessage="Subscribed!"/>
                                  :
                                  <FormattedMessage
                                      id="Footer.subscribeButtonLabelV2"
                                      description="Subscribe button label in footer"
                                      defaultMessage="Soumettre"/>
                          )
                    }
                  </button>
                </form>
            )
          }
        </Mutation>
    )
  }
}

export default NewsletterForm;