import React from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import logo from "./img/spradling-logo-landscape.svg";
import "./styles/Footer.scss";
import NewsletterForm from "components/Footer/components/NewsletterForm";

const Footer = ({intl}) => (
    <footer className="landing-footer">
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-4 mb-5">
            <nav className="nav flex-column">
              <a className="nav-link" href="http://www.spradling.eu/privacy-policy" target="_blank" rel="noreferrer noopener">
                <FormattedMessage
                    id="Footer.privacyPolicy"
                    description="Privacy Policy link text in Footer"
                    defaultMessage="Privacy Policy"/>
              </a>
              <a className="nav-link" href="http://www.spradling.eu/cookies-policy" target="_blank" rel="noreferrer noopener">
                <FormattedMessage
                    id="Footer.cookiesPolicy"
                    description="Cookies Policy link text in Footer"
                    defaultMessage="Cookies Policy"/>
              </a>
              <a className="nav-link" href="http://www.spradling.eu/legal-warning" target="_blank" rel="noreferrer noopener">
                <FormattedMessage
                    id="Footer.legalWarning"
                    description="Legal Warning link text in Footer"
                    defaultMessage="Legal Warning"/>
              </a>
            </nav>
          </div>
          <div className="col-12 col-lg-4 mb-5">
            <div className="footer-newsletter">
              <h5 className="footer-newsletter__title text-uppercase">
                <FormattedMessage
                    id="Footer.newsletterV2"
                    description="Newsletter section title in Footer"
                    defaultMessage="INSCRIVEZ-VOUS À NOTRE NEWSLETTER"/>
              </h5>
              <NewsletterForm/>
            </div>
          </div>
          <div className="col-12 col-lg-4 mb-5">
            <h5 className="text-uppercase">
              <FormattedMessage
                  id="Footer.socialNetworksV2"
                  description="Social Networks section title in Footer"
                  defaultMessage="SUIVEZ-NOUS SUR"/>
            </h5>
            <p className="footer-social-links">
              <a href="https://twitter.com/SpradlingEurope" target="_blank" rel="noreferrer noopener">
                <i className="fa fa-fw fa-twitter"/> Twitter
              </a>
              <a href="https://www.instagram.com/spradlingeurope/" target="_blank" rel="noreferrer noopener">
                <i className="fa fa-fw fa-instagram"/> Instagram
              </a>
              <a href="https://www.linkedin.com/company/10866412/" target="_blank" rel="noreferrer noopener">
                <i className="fa fa-fw fa-linkedin"/> LinkedIn
              </a>
              <a href="https://vimeo.com/spradlingeurope" target="_blank" rel="noreferrer noopener">
                <i className="fa fa-fw fa-vimeo"/> Vimeo
              </a>
            </p>
          </div>
        </div>
        <div className="footer-logo-and-copy text-center">
          <img src={logo} alt="Spradling Group"/>
          <p className="text-uppercase">
            &copy; {new Date().getFullYear()} Spradling Europe
          </p>
        </div>
      </div>
    </footer>
);

export default injectIntl(Footer);