import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import "bootstrap/dist/css/bootstrap.css";
import "font-awesome/css/font-awesome.css";
import "assets/fonts/gill-sans/stylesheet.css";
import "assets/styles/LandingMagliaApp.scss";
import "animate.css/animate.min.css";
import LandingMagliaApp from "app";
import {ApolloProvider} from "react-apollo";
import apolloClient from "apollo/client";

ReactDOM.render(
    <ApolloProvider client={apolloClient}>
      <LandingMagliaApp/>
    </ApolloProvider>,
    document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
