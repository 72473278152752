import React from "react";
import "./styles/FormSuccessLayer.scss";
import {FormattedMessage} from "react-intl";
import iconSuccess from "./img/icon-success.png";

const FormSuccessLayer = (props) => (
    <div className="form-success-layer">
      <div className="d-flex text-center align-items-center">
        <div className="form-success-layer-info">
          <h1>
            <FormattedMessage
                id="FormSuccessLayer.title"
                description="Title of FormSuccessLayer component"
                defaultMessage="Request realized."/>
          </h1>
          <img src={iconSuccess} alt="Success"/>
          <h5 className="bold">
            <FormattedMessage
                id="FormSuccessLayer.boldedText"
                description="Subtitle of FormSuccessLayer component"
                defaultMessage="Your order is in progress."/>
          </h5>
          <h5>
            <FormattedMessage
                id="FormSuccessLayer.text"
                description="Subtitle of FormSuccessLayer component"
                defaultMessage="Thank you for requesting our products"/>
          </h5>
        </div>
      </div>
    </div>
);

export default FormSuccessLayer;