import React from "react";
import {FormattedHTMLMessage, FormattedMessage} from "react-intl";
import {scroller} from "react-scroll";
import {useParams} from "react-router-dom";
import "./styles/Hero.scss";
import stampFr from "./img/coated-fabrics-m1.png";
import stampDe from "./img/coated-fabrics-b1.png";
import arrow from "./img/arrow-down.svg";

const Hero = (props) => {
  const {lang} = useParams();

  const handleButtonClick = () => {
    scroller.scrollTo("requestForm", {
      duration: 500,
      delay: 0,
      smooth: true,
      offset: -50
    });
  };

  return (
      <section className="landing-hero">
        <div className="container">
          <div className="row justify-content-center align-items-md-center">
            <div className="col-12 col-md-6 col-lg-4 order-md-1 text-center">
              <div className="hero-stamp d-flex align-items-center justify-content-center justify-content-md-end">
                <img
                    src={lang === "fr" ? stampFr : stampDe}
                    alt="Collection Certified"/>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-8 order-md-0">
              <div className="hero-content-wrapper d-flex align-items-center justify-content-center justify-content-md-start">
                <div className="hero-content text-center text-md-left">
                  <h1 className="hero-content__title text-uppercase">
                    SILVERTEX<sup>&reg;</sup> <FormattedMessage
                      id="Hero.and"
                      description="And in hero"
                      defaultMessage="AND"/> VALENCIA™
                  </h1>
                  <h3 className="hero-content__subtitle text-uppercase">
                    <FormattedHTMLMessage
                        id="Hero.subtitleV2"
                        description="Subtitle for Hero component"
                        defaultMessage="sont maintenant disponibles en version M1/B1"/>
                  </h3>
                  <button
                      className="hero-content__button btn btn-lg btn-primary text-uppercase"
                      onClick={handleButtonClick}>
                    <FormattedMessage
                        id="Hero.buttonLabelV2"
                        description="Button label for Hero component"
                        defaultMessage="Demander un échantillon"/>
                  </button>
                  <div className="floating-arrow">
                    <img
                        src={arrow}
                        alt=""/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
  );
};

export default Hero;