import React, {Component} from "react";
import _ from "lodash";
import $ from "jquery";
import classNames from 'classnames';
import {FormattedMessage, injectIntl, defineMessages, FormattedHTMLMessage} from "react-intl";
import createB1CollectionLeadMutation from "components/RequestForm/mutation";
import {graphql} from 'react-apollo'
import {COUNTRIES} from "components/RequestForm/constants/countries";
import {withRouter} from "react-router-dom";
import "./styles/RequestForm.scss";
import {Element} from "react-scroll";
import FormSuccessLayer from "components/RequestForm/components/FormSuccessLayer";
import silvertexColors from "components/ColourRange/data/silvertex";
import valenciaColors from "components/ColourRange/data/valencia";
import Select from 'react-select';

const reactSelectSilvertexOptions = silvertexColors.map(color => ({value: color.name, label: color.name}));
const reactSelectValenciaOptions = valenciaColors.map(color => ({value: color.name, label: color.name}));

class RequestForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      form: {
        name: '',
        surname: '',
        company: '',
        country: '',
        email: '',
        phone: '',
        companyType: '',
        request: '',
        swatchbookCollections: [],
        silvertexColors: [],
        valenciaColors: [],
        address: '',
        postalCode: '',
        city: '',
      },
      loading: false,
      success: null
    };

    this.handleTextInputChange = field => this._handleTextInputChange.bind(this, field);
    this.handleSilvertexColorsSelectChange = this.handleSilvertexColorsSelectChange.bind(this);
    this.handleValenciaColorsSelectChange = this.handleValenciaColorsSelectChange.bind(this);
    this.handleSwatchBookCheckboxChange = this.handleSwatchBookCheckboxChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    window.bctag({
      config: {
        token: 'A4214F4EDC1F5BDA91680A6F544B1A71',
        // redirect: "gracias.html"
      },
      forms: {
        // Selector de Formulario puede ser una clase o ID o un elemento directo
        ".request-maglia-form": {
          contact_name: "name",
          contact_lastname: "surname",
          contact_email: "email",
          contact_phone: "phone",
          contact_address: "address",
          contact_city: "city",
          lead_msg: "email",
          custom: {
            company_type: "companyType",
            request: "request",
            colours_silvertex: "",
            colours_valencia: "",
            postal_code: "postalCode",
            company: "company",
            country: "country",
            swatchbook: "", // campo opcional le quitamos el enlance y a traves del callback lo llenamo//
          }
        }//-- form
      },
      callback: {
        //--- llamada antes de enviar los datos ----//
        beforeSend: function (data) {
          var InputCompany = $("#companyType");

          //--- transformaciones ---//
          var Company = { "MANUFACTURER" : 0, "DISTRIBUTOR": 1, "ARCHITECT" : 2 , "DESIGNER" : 3 , "OTHER" : 4  };
          var Request = { "DINA4_SAMPLE" : 0, "SWATCHBOOK" : 1, "COLOUR_CARD" : 2, "DIGITAL_CATALOGUE" : 3, "MORE_INFO": 4 };

          //--- si el selector concuerda con swatchbook --//
          if (["DINA4_SAMPLE", "SWATCHBOOK", "COLOUR_CARD"].indexOf($("#request").val()) > -1){
            data.contact_address = $("#address").val().trim();
            data.contact_city = $("#city").val().trim();
          }

          if ($("#request").val() == "SWATCHBOOK")
            data.custom.swatchbook = $("#swatchbook").val().trim();

          if ($("#request").val() == "DINA4_SAMPLE") {
            data.custom.colours_valencia = $("#valenciaColors").val().trim();
            data.custom.colours_silvertex = $("#silvertexColors").val().trim();
          }

          //-- transformamos el tipo de compañia ---//
          if( Company.hasOwnProperty( InputCompany.val().trim() ) ){
            data.custom.company_type = Company[ InputCompany.val().trim() ];
          }

          //--- transformamos  el request ---//
          if( Request.hasOwnProperty( $("#request").val() ) ){
            data.custom.request = Request[ $("#request").val() ];
          }

          //-- retornamos a la función original para enviar los datos  ---//
          return data;
        }
        //-- tambien tiene un callback de complete --//
        //complete: function(json,form){}
      }
    });
  }

  /**
   * Handle changes to all form inputs and update the state with the new data
   * @param field
   * @param event
   * @private
   */
  _handleTextInputChange(field, event) {
    const value = event.target.value;
    this.setState({
      form: {
        ...this.state.form,
        [field]: value
      }
    }, () => {
      if (field === 'request') {
        if (value !== 'DINA4_SAMPLE') {
          this.setState({
            form: {
              ...this.state.form,
              silvertexColors: '',
              valenciaColors: ''
            }
          })
        }
        if (['DIGITAL_CATALOGUE', 'MORE_INFO'].includes(value)) {
          this.setState({
            form: {
              ...this.state.form,
              silvertexColors: '',
              valenciaColors: '',
              address: '',
              postalCode: '',
              city: '',
            }
          })
        }
      }
    });
  }

  /**
   * Handles changes in the colors select box
   * @param event
   */
  handleSilvertexColorsSelectChange(selectedColors) {
    const {valenciaColors} = this.state.form;
    if (selectedColors.length + valenciaColors.length < 11)
      this.setState({
        form: {
          ...this.state.form,
          silvertexColors: selectedColors
        }
      });
  }

  /**
   * Handles changes in the colors select box
   * @param event
   */
  handleValenciaColorsSelectChange(selectedColors) {
    const {silvertexColors} = this.state.form;
    if (silvertexColors.length + selectedColors.length < 11)
      this.setState({
        form: {
          ...this.state.form,
          valenciaColors: selectedColors
        }
      });
  }

  /**
   * Handles changes on the swatch checkboxes
   * @param event
   */
  handleSwatchBookCheckboxChange(event) {
    const {target} = event;
    const {collection} = target.dataset;
    let newValue = this.state.form.swatchbookCollections;
    if (target.checked)
      newValue.push(collection);
    else
      newValue = _.without(newValue, collection);
    this.setState({
      form: {
        ...this.state.form,
        swatchbookCollections: newValue
      }
    });
  }

  /**
   * Handle form submit and calls createB1CollectionLeadMutation mutation
   * @param event
   */
  handleSubmit(event) {
    // event.preventDefault();

    this.setState({
      loading: true
    });

    const {intl, location} = this.props;
    const {form} = this.state;
    const country = COUNTRIES.find(item => item.country.defaultMessage === form.country);

    let silvertexColors = null;
    if (form.silvertexColors.length > 0)
      silvertexColors = form.silvertexColors.map(color => color.value);

    let swatchbookCollections = null;
    if (form.swatchbookCollections.length > 0)
      swatchbookCollections = form.swatchbookCollections;

    let valenciaColors = null;
    if (form.valenciaColors.length > 0)
      valenciaColors = form.valenciaColors.map(color => color.value);

    this.props.createB1CollectionLeadMutation({
      variables: {
        input: {
          ...form,
          country: intl.formatMessage(country.country),
          // recipient: 'cristianguillermorojas@gmail.com',
          recipient: country.recipient,
          lang: location.pathname.substring(1, 3),
          swatchbookCollections: swatchbookCollections ? swatchbookCollections.join(",") : null,
          silvertexColors: silvertexColors ? silvertexColors.join(",") : null,
          valenciaColors: valenciaColors ? valenciaColors.join(",") : null,
          address: form.address !== '' ? form.address : null,
          postalCode: form.postalCode !== '' ? form.postalCode : null,
          city: form.city !== '' ? form.city : null,
        }
      }
    }).then(response => {
      this.resetFormData();
      this.setState({
        loading: false,
        success: true
      })
    }).catch(error => {
      // this.resetFormData();
      this.setState({
        loading: false,
        success: false
      })
    })
  }

  /**
   * Reset all the state data to restart the form
   */
  resetFormData() {
    this.setState({
      form: {
        name: '',
        surname: '',
        company: '',
        country: '',
        email: '',
        phone: '',
        companyType: '',
        request: '',
      }
    })
  }

  /**
   * Returns and array with all options with translated text of the company types select input
   * @return {*[]}
   */
  getCompanyTypesOptions() {
    const {formatMessage} = this.props.intl;
    return [
      {
        value: "",
        label: formatMessage(defineMessages({
              companyTypeFormField: {
                id: "RequestForm.companyTypeFormField",
                description: "default option of Company Type field",
                defaultMessage: "Company Type"
              }
            }).companyTypeFormField
        )
      },
      {
        value: "MANUFACTURER",
        label: formatMessage(defineMessages({
              manufacturerOptionCompanyTypeField: {
                id: "RequestForm.manufacturerOptionCompanyTypeField",
                description: "Manufacturer option of Company Type field",
                defaultMessage: "Manufacturer",
              }
            }).manufacturerOptionCompanyTypeField
        )
      },
      {
        value: "DISTRIBUTOR",
        label: formatMessage(defineMessages({
              distributorOptionCompanyTypeField: {
                id: "RequestForm.distributorOptionCompanyTypeField",
                description: "Distributor option of Company Type field",
                defaultMessage: "Distributor",
              }
            }).distributorOptionCompanyTypeField
        )
      },
      {
        value: "ARCHITECT",
        label: formatMessage(defineMessages({
              architectOptionCompanyTypeField: {
                id: "RequestForm.architectOptionCompanyTypeField",
                description: "Architect option of Company Type field",
                defaultMessage: "Architect",
              }
            }).architectOptionCompanyTypeField
        )
      },
      {
        value: "DESIGNER",
        label: formatMessage(defineMessages({
              designOptionCompanyTypeField: {
                id: "RequestForm.designOptionCompanyTypeField",
                description: "Designer option of Company Type field",
                defaultMessage: "Designer",
              }
            }).designOptionCompanyTypeField
        )
      },
      {
        value: "OTHER",
        label: formatMessage(defineMessages({
              otherOptionCompanyTypeField: {
                id: "RequestForm.otherOptionCompanyTypeField",
                description: "Other option of Company Type field",
                defaultMessage: "Other",
              }
            }).otherOptionCompanyTypeField
        )
      },
    ]
  }

  /**
   * Returns and array with all options with translated text of the request select input
   * @return {*[]}
   */
  getRequestOptions() {
    const {formatMessage} = this.props.intl;
    return [
      {
        value: "",
        label: formatMessage(defineMessages({
              defaultOptionRequestField: {
                id: "RequestForm.defaultOptionRequestField",
                description: "default option of Request field",
                defaultMessage: "Request",
              }
            }).defaultOptionRequestField
        )
      },
      {
        value: "DINA4_SAMPLE",
        label: formatMessage(defineMessages({
              sampleOptionRequestField: {
                id: "RequestForm.dinA4sampleOptionRequestField",
                description: "DinA4 Samples option of Request field",
                defaultMessage: "DinA4 Samples",
              }
            }).sampleOptionRequestField
        )
      },
      {
        value: "SWATCHBOOK",
        label: formatMessage(defineMessages({
              swatchbookCatalogueOptionRequestField: {
                id: "RequestForm.swatchbookCatalogueOptionRequestField",
                description: "Swatchbook option of Request field",
                defaultMessage: "Swatchbook",
              }
            }).swatchbookCatalogueOptionRequestField
        )
      },
      {
        value: "COLOUR_CARD",
        label: formatMessage(defineMessages({
              colourCardatalogueOptionRequestField: {
                id: "RequestForm.colourCardatalogueOptionRequestField",
                description: "Colour Card option of Request field",
                defaultMessage: "Colour Card",
              }
            }).colourCardatalogueOptionRequestField
        )
      },
      {
        value: "DIGITAL_CATALOGUE",
        label: formatMessage(defineMessages({
              digitalCatalogueOptionRequestField: {
                id: "RequestForm.digitalCatalogueOptionRequestField",
                description: "Digital Catalogue option of Request field",
                defaultMessage: "Digital Catalogue",
              }
            }).digitalCatalogueOptionRequestField
        )
      },
      {
        value: "MORE_INFO",
        label: formatMessage(defineMessages({
              moreInfoOptionRequestField: {
                id: "RequestForm.moreInfoOptionRequestField",
                description: "More Information option of Request field",
                defaultMessage: "More Information",
              }
            }).moreInfoOptionRequestField
        )
      },
    ]
  }

  render() {
    const {formatMessage} = this.props.intl;
    const {name, surname, company, country, email, phone, companyType, request, silvertexColors, valenciaColors, swatchbookCollections, address, postalCode, city} = this.state.form;
    const {loading, success} = this.state;

    return (
        <Element name="requestForm">
          <section className="request-form mb-5">
            <div className="container">
              <h2>
                <FormattedMessage
                    id="RequestForm.titleV2"
                    description="Title for RequestForm component"
                    defaultMessage="Êtes-vous prêt à faire partie de cette nouvelle époque des tissus enduits?"/>
              </h2>
              <h3>
                <FormattedHTMLMessage
                    id="RequestForm.subtitleV3"
                    description="Subtitle for RequestForm component"
                    defaultMessage="Demandez SILVERTEX<sup>&reg;</sup> B1 and VALENCIA™ B1 ici!"/>
              </h3>
              <div className="maglia-form-wrapper">
                <form className="request-maglia-form" onSubmit={this.handleSubmit}>
                  <div style={{
                    display: "none"
                  }}>
                    <input type="text" name="silvertexColors" id="silvertexColors" readOnly
                           value={silvertexColors && silvertexColors.map(color => color.value).join(",")}/>
                    <input type="text" name="valenciaColors" id="valenciaColors" readOnly
                           value={valenciaColors && valenciaColors.map(color => color.value).join(",")}/>
                    <input type="text" name="swatchbook" id="swatchbook" readOnly
                           value={swatchbookCollections && swatchbookCollections.join(",")}/>
                  </div>
                  <div className={classNames({
                    "form-group": true,
                    "form-group--filled": name !== ''
                  })}>
                    <label htmlFor="name">
                      <FormattedMessage
                          id="RequestForm.nameFormField"
                          description="Name field of the RequestForm"
                          defaultMessage="Name"/>
                    </label>
                    <input
                        value={name}
                        onChange={this.handleTextInputChange('name')}
                        type="text"
                        className="form-control"
                        name="name"
                        id="name"
                        required
                        placeholder={
                          formatMessage(defineMessages({
                            nameFieldPlaceholder: {
                              id: "RequestForm.nameFieldPlaceholder",
                              description: "Placeholder to the Name field",
                              defaultMessage: "Name",
                            }
                          }).nameFieldPlaceholder)
                        }/>
                  </div>
                  <div className={classNames({
                    "form-group": true,
                    "form-group--filled": surname !== ''
                  })}>
                    <label htmlFor="surname">
                      <FormattedMessage
                          id="RequestForm.surnameFormField"
                          description="Surname field of the RequestForm"
                          defaultMessage="Surname"/>
                    </label>
                    <input
                        value={surname}
                        onChange={this.handleTextInputChange('surname')}
                        type="text"
                        className="form-control"
                        id="surname"
                        name="surname"
                        required
                        placeholder={
                          formatMessage(defineMessages({
                            surnameFieldPlaceholder: {
                              id: "RequestForm.surnameFieldPlaceholder",
                              description: "Placeholder to the Surname field",
                              defaultMessage: "Surname",
                            }
                          }).surnameFieldPlaceholder)
                        }/>
                  </div>
                  <div className={classNames({
                    "form-group": true,
                    "form-group--filled": company !== ''
                  })}>
                    <label htmlFor="company">
                      <FormattedMessage
                          id="RequestForm.companyFormField"
                          description="Company field of the RequestForm"
                          defaultMessage="Company"/>
                    </label>
                    <input
                        value={company}
                        onChange={this.handleTextInputChange('company')}
                        type="text"
                        className="form-control"
                        id="company"
                        name="company"
                        required
                        placeholder={
                          formatMessage(defineMessages({
                            companyFieldPlaceholder: {
                              id: "RequestForm.companyFieldPlaceholder",
                              description: "Placeholder to the Company field",
                              defaultMessage: "Company",
                            }
                          }).companyFieldPlaceholder)
                        }/>
                  </div>
                  <div className={classNames({
                    "form-group": true,
                    "form-group--filled": companyType !== ''
                  })}>
                    <label htmlFor="companyType">
                      <FormattedMessage
                          id="RequestForm.companyTypeFormFieldLabel"
                          description="Company Type field of the RequestForm"
                          defaultMessage="Company Type"/>
                    </label>
                    <select
                        name="companyType"
                        id="companyType"
                        required
                        value={companyType}
                        onChange={this.handleTextInputChange('companyType')}
                        className="form-control">
                      {
                        this.getCompanyTypesOptions().map((option, index) => (
                            <option disabled={index === 0} key={index} value={option.value}>{option.label}</option>
                        ))
                      }
                    </select>
                  </div>
                  <div className={classNames({
                    "form-group": true,
                    "form-group--filled": country !== ''
                  })}>
                    <label htmlFor="country">
                      <FormattedMessage
                          id="RequestForm.countryFormField"
                          description="Country field of the RequestForm"
                          defaultMessage="Country"/>
                    </label>
                    <select
                        name="country"
                        id="country"
                        required
                        value={country}
                        onChange={this.handleTextInputChange('country')}
                        className="form-control text-capitalize">
                      <option value="" disabled>
                        {
                          formatMessage(defineMessages({
                            defaultOptionCountryField: {
                              id: "RequestForm.defaultOptionCountryField",
                              description: "default option of Country field",
                              defaultMessage: "Choose the Country",
                            }
                          }).defaultOptionCountryField)
                        }
                      </option>
                      {
                        COUNTRIES.map((option, index) => (
                            <option key={index} value={option.country.defaultMessage}>
                              {
                                formatMessage(option.country)
                              }
                            </option>
                        ))
                      }
                    </select>
                  </div>
                  <div className={classNames({
                    "form-group": true,
                    "form-group--filled": request !== ''
                  })}>
                    <label htmlFor="request">
                      <FormattedMessage
                          id="RequestForm.requestFormField"
                          description="Request field of the RequestForm"
                          defaultMessage="Request"/>
                    </label>
                    <select
                        name="request"
                        id="request"
                        required
                        value={request}
                        onChange={this.handleTextInputChange('request')}
                        className="form-control">
                      {
                        this.getRequestOptions().map((option, index) => (
                            <option disabled={index === 0} key={index} value={option.value}>{option.label}</option>
                        ))
                      }
                    </select>
                  </div>
                  {
                    request === "SWATCHBOOK" &&
                    <React.Fragment>
                      <p className="text-center" style={{
                        textDecoration: "underline"
                      }}>
                        <FormattedMessage
                            id="RequestForm.selectSwatchBooks"
                            description="Select swatch books message"
                            defaultMessage="Please select the collection(s) you want"/>
                      </p>
                      <div className="form-row">
                        <div className="form-group col-6">
                          <div className="form-check">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                id="swatch-book-silvertex"
                                data-collection="silvertex"
                                onChange={this.handleSwatchBookCheckboxChange}/>
                            <label className="form-check-label" htmlFor="swatch-book-silvertex">
                              Silvertex<sup>&reg;</sup> B1
                            </label>
                          </div>
                        </div>
                        <div className="form-group col-6">
                          <div className="form-check">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                id="swatch-book-valencia"
                                data-collection="valencia"
                                onChange={this.handleSwatchBookCheckboxChange}/>
                            <label className="form-check-label" htmlFor="swatch-book-valencia">
                              Valencia™ B1
                            </label>
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  }
                  {
                    request === "DINA4_SAMPLE" &&
                    <React.Fragment>
                      <p className="text-center" style={{
                        textDecoration: "underline"
                      }}>
                        <FormattedMessage
                            id="RequestForm.selectUpTo10"
                            description="Select up to 10 message"
                            defaultMessage="Select up to 10 samples"/>
                      </p>
                      <div className="form-row">
                        <div className={classNames({
                          "form-group col-6": true,
                          "form-group--filled": silvertexColors.length > 0
                        })}>
                          <label htmlFor="silvertexColors">
                            <FormattedMessage
                                id="RequestForm.silvertexColorsFormField"
                                description="Color field of the RequestForm"
                                defaultMessage="Silvertex B1"/>
                          </label>
                          <FormattedMessage
                              id="RequestForm.silvertexColorsSelectPlaceholder"
                              description="Placeholder for colors select"
                              defaultMessage="Silvertex B1">
                            {
                              placeholder => (
                                  <Select
                                      options={reactSelectSilvertexOptions}
                                      isMulti
                                      value={silvertexColors}
                                      placeholder={placeholder}
                                      name="silvertexColors"
                                      onChange={this.handleSilvertexColorsSelectChange}/>
                              )
                            }
                          </FormattedMessage>
                        </div>
                        <div className={classNames({
                          "form-group col-6": true,
                          "form-group--filled": valenciaColors.length > 0
                        })}>
                          <label htmlFor="valenciaColors">
                            <FormattedMessage
                                id="RequestForm.valenciaColorsFormField"
                                description="Color field of the RequestForm"
                                defaultMessage="Valencia B1"/>
                          </label>
                          <FormattedMessage
                              id="RequestForm.valenciaColorsSelectPlaceholder"
                              description="Placeholder for colors select"
                              defaultMessage="Valencia B1">
                            {
                              placeholder => (
                                  <Select
                                      options={reactSelectValenciaOptions}
                                      isMulti
                                      value={valenciaColors}
                                      placeholder={placeholder}
                                      name="valenciaColors"
                                      onChange={this.handleValenciaColorsSelectChange}/>
                              )
                            }
                          </FormattedMessage>
                        </div>
                        {
                          (silvertexColors.length > 0 || valenciaColors.length > 0) &&
                          <div className="form-group col-12">
                            <small className="form-text text-muted">
                              <FormattedMessage
                                  id="RequestForm.colorsHelpTextSelected"
                                  description="Number of selected colors for colors select"
                                  defaultMessage="{length} selected."
                                  values={{
                                    length: silvertexColors.length + valenciaColors.length
                                  }}/>
                            </small>
                          </div>
                        }
                      </div>
                    </React.Fragment>
                  }
                  {
                    ["DINA4_SAMPLE", "SWATCHBOOK", "COLOUR_CARD"].includes(request) &&
                    <React.Fragment>
                      <div className={classNames({
                        "form-group": true,
                        "form-group--filled": address !== ''
                      })}>
                        <label htmlFor="address">
                          <FormattedMessage
                              id="RequestForm.addressFormField"
                              description="Address field of the RequestForm"
                              defaultMessage="Address"/>
                        </label>
                        <input
                            value={address}
                            onChange={this.handleTextInputChange('address')}
                            type="text"
                            className="form-control"
                            id="address"
                            name="address"
                            required
                            placeholder={
                              formatMessage(defineMessages({
                                addressFieldPlaceholder: {
                                  id: "RequestForm.addressFieldPlaceholder",
                                  description: "Placeholder to the Address field",
                                  defaultMessage: "Address",
                                }
                              }).addressFieldPlaceholder)
                            }/>
                      </div>
                      <div className={classNames({
                        "form-group": true,
                        "form-group--filled": postalCode !== ''
                      })}>
                        <label htmlFor="postalCode">
                          <FormattedMessage
                              id="RequestForm.postalCodeFormField"
                              description="Postal Code field of the RequestForm"
                              defaultMessage="Postal Code"/>
                        </label>
                        <input
                            value={postalCode}
                            onChange={this.handleTextInputChange('postalCode')}
                            type="text"
                            className="form-control"
                            name="postalCode"
                            id="postalCode"
                            required
                            placeholder={
                              formatMessage(defineMessages({
                                postalCodeFieldPlaceholder: {
                                  id: "RequestForm.postalCodeFieldPlaceholder",
                                  description: "Placeholder to the Postal Code field",
                                  defaultMessage: "Postal Code",
                                }
                              }).postalCodeFieldPlaceholder)
                            }/>
                      </div>
                      <div className={classNames({
                        "form-group": true,
                        "form-group--filled": city !== ''
                      })}>
                        <label htmlFor="city">
                          <FormattedMessage
                              id="RequestForm.cityFormField"
                              description="City field of the RequestForm"
                              defaultMessage="City"/>
                        </label>
                        <input
                            value={city}
                            onChange={this.handleTextInputChange('city')}
                            type="text"
                            className="form-control"
                            id="city"
                            name="city"
                            required
                            placeholder={
                              formatMessage(defineMessages({
                                cityFieldPlaceholder: {
                                  id: "RequestForm.cityFieldPlaceholder",
                                  description: "Placeholder to the City field",
                                  defaultMessage: "City",
                                }
                              }).cityFieldPlaceholder)
                            }/>
                      </div>
                    </React.Fragment>

                  }
                  <div className={classNames({
                    "form-group": true,
                    "form-group--filled": email !== ''
                  })}>
                    <label htmlFor="email">
                      <FormattedMessage
                          id="RequestForm.emailFormField"
                          description="Email field of the RequestForm"
                          defaultMessage="Email"/>
                    </label>
                    <input
                        value={email}
                        onChange={this.handleTextInputChange('email')}
                        type="email"
                        className="form-control"
                        id="email"
                        name="email"
                        required
                        placeholder={
                          formatMessage(defineMessages({
                            emailFieldPlaceholder: {
                              id: "RequestForm.emailFieldPlaceholder",
                              description: "Placeholder to the Email field",
                              defaultMessage: "Email",
                            }
                          }).emailFieldPlaceholder)
                        }/>
                  </div>

                  <div className={classNames({
                    "form-group": true,
                    "form-group--filled": phone !== ''
                  })}>
                    <label htmlFor="phone">
                      <FormattedMessage
                          id="RequestForm.phoneFormField"
                          description="Phone field of the RequestForm"
                          defaultMessage="Phone"/>
                    </label>
                    <input
                        value={phone}
                        onChange={this.handleTextInputChange('phone')}
                        type="text"
                        className="form-control"
                        name="phone"
                        id="phone"
                        required
                        placeholder={
                          formatMessage(defineMessages({
                            phoneFieldPlaceholder: {
                              id: "RequestForm.phoneFieldPlaceholder",
                              description: "Placeholder to the Phone field",
                              defaultMessage: "Phone",
                            }
                          }).phoneFieldPlaceholder)
                        }/>
                  </div>
                  <button
                      type="submit"
                      className="btn btn-primary btn-block text-uppercase"
                      disabled={loading}>
                    {
                      loading
                          ?
                          <FormattedMessage
                              id="RequestForm.submitButtonLoadingText"
                              description="Loading text for submit button of the RequestForm"
                              defaultMessage="Sending..."/>
                          :
                          <FormattedMessage
                              id="RequestForm.submitButtonTextV2"
                              description="Submit button of the RequestForm"
                              defaultMessage="Soumettre"/>
                    }
                  </button>
                </form>
                {
                  success === true && <FormSuccessLayer/>
                }
              </div>
            </div>
          </section>
        </Element>
    )
  }
}

export default injectIntl(
    withRouter(
        graphql(createB1CollectionLeadMutation, {name: 'createB1CollectionLeadMutation'})(RequestForm)
    )
);