import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames/bind";
import "./styles/TitleWithDivider.scss";

const TitleWithDivider = ({title, color}) => (
    <div className="title-with-divider">
      <h4 className={classNames({
        "title-with-divider__title": true,
        [`title-with-divider__title--${color}`]: true
      })}>
        {title}
      </h4>
      <hr className="title-with-divider__divider"/>
    </div>
);

TitleWithDivider.propTypes = {
  title: PropTypes.node.isRequired,
  color: PropTypes.string.isRequired
};

TitleWithDivider.defaultProps = {
  color: "black"
};

export default TitleWithDivider;