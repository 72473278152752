import React, {useState} from "react";
import PropTypes from "prop-types";
import TitleWithDivider from "utils/components/TitleWithDivider";
import ColourCard from "components/ColourRange/components/ColourCard";
import SelectedColor from "components/ColourRange/components/SelectedColor";

export default function ColoursTab({colors, title, subtitle}) {
  const [selectedColor, setSelectedColor] = useState(null);

  /**
   * Handles clicks on the color card component
   * @param color
   */
  function handleColorClick(color) {
    setSelectedColor(color);
  }

  /**
   * Handles click on the selected color component's close button
   * @param event
   */
  function handleSelectedColorCloseButtonClick(event) {
    setSelectedColor(null);
  }

  return (
      <div className="colour-range-tab">
        <TitleWithDivider
            title={title}/>
        <p className="text-justify">
          {subtitle}
        </p>
        <div className="colour-cards">
          <div className="row">
            {
              colors.map((color, index) =>
                  <div
                      key={index}
                      className="col-4 col-md-3 col-lg-2">
                    <ColourCard
                        product={color}
                        onClick={() => handleColorClick(color)}/>
                  </div>
              )
            }
          </div>
          {
            selectedColor &&
            <SelectedColor
                product={selectedColor}
                onCloseButtonClick={handleSelectedColorCloseButtonClick}/>
          }
        </div>
      </div>
  );
}

ColoursTab.propTypes = {
  title: PropTypes.node.isRequired,
  subtitle: PropTypes.node.isRequired,
  colors: PropTypes.array,
};