import {defineMessages} from "react-intl";

export const COUNTRIES = [
  {
    country: defineMessages({
      afghanistan: {
        id: "countryField.afghanistan",
        description: "AFGHANISTAN country option of counry field",
        defaultMessage: "AFGHANISTAN".toLowerCase()
      }
    }).afghanistan, recipient: "info.de@spradling.eu"
  },
  {
    country: defineMessages({
      albania: {
        id: "countryField.albania",
        description: "ALBANIA country option of counry field",
        defaultMessage: "ALBANIA".toLowerCase()
      }
    }).albania, recipient: "info.de@spradling.eu"
  },
  {
    country: defineMessages({
      algeria: {
        id: "countryField.algeria",
        description: "ALGERIA country option of counry field",
        defaultMessage: "ALGERIA".toLowerCase()
      }
    }).algeria, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      americanSamoa: {
        id: "countryField.americanSamoa",
        description: "AMERICAN SAMOA country option of counry field",
        defaultMessage: "AMERICAN SAMOA".toLowerCase()
      }
    }).americanSamoa, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      andorra: {
        id: "countryField.andorra",
        description: "ANDORRA country option of counry field",
        defaultMessage: "ANDORRA".toLowerCase()
      }
    }).andorra, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      angola: {
        id: "countryField.angola",
        description: "ANGOLA country option of counry field",
        defaultMessage: "ANGOLA".toLowerCase()
      }
    }).angola, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      anguilla: {
        id: "countryField.anguilla",
        description: "ANGUILLA country option of counry field",
        defaultMessage: "ANGUILLA".toLowerCase()
      }
    }).anguilla, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      antarctica: {
        id: "countryField.antarctica",
        description: "ANTARCTICA country option of counry field",
        defaultMessage: "ANTARCTICA".toLowerCase()
      }
    }).antarctica, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      antiguaAndBarbuda: {
        id: "countryField.antiguaAndBarbuda",
        description: "ANTIGUA AND BARBUDA country option of counry field",
        defaultMessage: "ANTIGUA AND BARBUDA".toLowerCase()
      }
    }).antiguaAndBarbuda, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      argentina: {
        id: "countryField.argentina",
        description: "ARGENTINA country option of counry field",
        defaultMessage: "ARGENTINA".toLowerCase()
      }
    }).argentina, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      armenia: {
        id: "countryField.armenia",
        description: "ARMENIA country option of counry field",
        defaultMessage: "ARMENIA".toLowerCase()
      }
    }).armenia, recipient: "info.de@spradling.eu"
  },
  {
    country: defineMessages({
      aruba: {
        id: "countryField.aruba",
        description: "ARUBA country option of counry field",
        defaultMessage: "ARUBA".toLowerCase()
      }
    }).aruba, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      australia: {
        id: "countryField.australia",
        description: "AUSTRALIA country option of counry field",
        defaultMessage: "AUSTRALIA".toLowerCase()
      }
    }).australia, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      austria: {
        id: "countryField.austria",
        description: "AUSTRIA country option of counry field",
        defaultMessage: "AUSTRIA".toLowerCase()
      }
    }).austria, recipient: "info.de@spradling.eu"
  },
  {
    country: defineMessages({
      azerbaijan: {
        id: "countryField.azerbaijan",
        description: "AZERBAIJAN country option of counry field",
        defaultMessage: "AZERBAIJAN".toLowerCase()
      }
    }).azerbaijan, recipient: "info.de@spradling.eu"
  },
  {
    country: defineMessages({
      bahamas: {
        id: "countryField.bahamas",
        description: "BAHAMAS country option of counry field",
        defaultMessage: "BAHAMAS".toLowerCase()
      }
    }).bahamas, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      bahrain: {
        id: "countryField.bahrain",
        description: "BAHRAIN country option of counry field",
        defaultMessage: "BAHRAIN".toLowerCase()
      }
    }).bahrain, recipient: "info.de@spradling.eu"
  },
  {
    country: defineMessages({
      bangladesh: {
        id: "countryField.bangladesh",
        description: "BANGLADESH country option of counry field",
        defaultMessage: "BANGLADESH".toLowerCase()
      }
    }).bangladesh, recipient: "info.de@spradling.eu"
  },
  {
    country: defineMessages({
      barbados: {
        id: "countryField.barbados",
        description: "BARBADOS country option of counry field",
        defaultMessage: "BARBADOS".toLowerCase()
      }
    }).barbados, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      belarus: {
        id: "countryField.belarus",
        description: "BELARUS country option of counry field",
        defaultMessage: "BELARUS".toLowerCase()
      }
    }).belarus, recipient: "info.de@spradling.eu"
  },
  {
    country: defineMessages({
      belgium: {
        id: "countryField.belgium",
        description: "BELGIUM country option of counry field",
        defaultMessage: "BELGIUM".toLowerCase()
      }
    }).belgium, recipient: "info.de@spradling.eu"
  },
  {
    country: defineMessages({
      belize: {
        id: "countryField.belize",
        description: "BELIZE country option of counry field",
        defaultMessage: "BELIZE".toLowerCase()
      }
    }).belize, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      benin: {
        id: "countryField.benin",
        description: "BENIN country option of counry field",
        defaultMessage: "BENIN".toLowerCase()
      }
    }).benin, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      bermuda: {
        id: "countryField.bermuda",
        description: "BERMUDA country option of counry field",
        defaultMessage: "BERMUDA".toLowerCase()
      }
    }).bermuda, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      bhutan: {
        id: "countryField.bhutan",
        description: "BHUTAN country option of counry field",
        defaultMessage: "BHUTAN".toLowerCase()
      }
    }).bhutan, recipient: "info.de@spradling.eu"
  },
  {
    country: defineMessages({
      bolivia: {
        id: "countryField.bolivia",
        description: "BOLIVIA country option of counry field",
        defaultMessage: "BOLIVIA".toLowerCase()
      }
    }).bolivia, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      bosniaAndHerzegowina: {
        id: "countryField.bosniaAndHerzegowina",
        description: "BOSNIA AND HERZEGOWINA country option of counry field",
        defaultMessage: "BOSNIA AND HERZEGOWINA".toLowerCase()
      }
    }).bosniaAndHerzegowina, recipient: "info.de@spradling.eu"
  },
  {
    country: defineMessages({
      botswana: {
        id: "countryField.botswana",
        description: "BOTSWANA country option of counry field",
        defaultMessage: "BOTSWANA".toLowerCase()
      }
    }).botswana, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      bouvetIsland: {
        id: "countryField.bouvetIsland",
        description: "BOUVET ISLAND country option of counry field",
        defaultMessage: "BOUVET ISLAND".toLowerCase()
      }
    }).bouvetIsland, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      brazil: {
        id: "countryField.brazil",
        description: "BRAZIL country option of counry field",
        defaultMessage: "BRAZIL".toLowerCase()
      }
    }).brazil, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      britishIndianOceanTerritory: {
        id: "countryField.britishIndianOceanTerritory",
        description: "BRITISH INDIAN OCEAN TERRITORY country option of counry field",
        defaultMessage: "BRITISH INDIAN OCEAN TERRITORY".toLowerCase()
      }
    }).britishIndianOceanTerritory, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      bruneiDarussalam: {
        id: "countryField.bruneiDarussalam",
        description: "BRUNEI DARUSSALAM country option of counry field",
        defaultMessage: "BRUNEI DARUSSALAM".toLowerCase()
      }
    }).bruneiDarussalam, recipient: "info.de@spradling.eu"
  },
  {
    country: defineMessages({
      bulgaria: {
        id: "countryField.bulgaria",
        description: "BULGARIA country option of counry field",
        defaultMessage: "BULGARIA".toLowerCase()
      }
    }).bulgaria, recipient: "info.de@spradling.eu"
  },
  {
    country: defineMessages({
      burkinaFaso: {
        id: "countryField.burkinaFaso",
        description: "BURKINA FASO country option of counry field",
        defaultMessage: "BURKINA FASO".toLowerCase()
      }
    }).burkinaFaso, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      burundi: {
        id: "countryField.burundi",
        description: "BURUNDI country option of counry field",
        defaultMessage: "BURUNDI".toLowerCase()
      }
    }).burundi, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      cambodia: {
        id: "countryField.cambodia",
        description: "CAMBODIA country option of counry field",
        defaultMessage: "CAMBODIA".toLowerCase()
      }
    }).cambodia, recipient: "info.de@spradling.eu"
  },
  {
    country: defineMessages({
      cameroon: {
        id: "countryField.cameroon",
        description: "CAMEROON country option of counry field",
        defaultMessage: "CAMEROON".toLowerCase()
      }
    }).cameroon, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      canada: {
        id: "countryField.canada",
        description: "CANADA country option of counry field",
        defaultMessage: "CANADA".toLowerCase()
      }
    }).canada, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      capeVerde: {
        id: "countryField.capeVerde",
        description: "CAPE VERDE country option of counry field",
        defaultMessage: "CAPE VERDE".toLowerCase()
      }
    }).capeVerde, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      caymanIslands: {
        id: "countryField.caymanIslands",
        description: "CAYMAN ISLANDS country option of counry field",
        defaultMessage: "CAYMAN ISLANDS".toLowerCase()
      }
    }).caymanIslands, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      centralAfricanRepublic: {
        id: "countryField.centralAfricanRepublic",
        description: "CENTRAL AFRICAN REPUBLIC country option of counry field",
        defaultMessage: "CENTRAL AFRICAN REPUBLIC".toLowerCase()
      }
    }).centralAfricanRepublic, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      chad: {
        id: "countryField.chad",
        description: "CHAD country option of counry field",
        defaultMessage: "CHAD".toLowerCase()
      }
    }).chad, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      chile: {
        id: "countryField.chile",
        description: "CHILE country option of counry field",
        defaultMessage: "CHILE".toLowerCase()
      }
    }).chile, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      china: {
        id: "countryField.china",
        description: "CHINA country option of counry field",
        defaultMessage: "CHINA".toLowerCase()
      }
    }).china, recipient: "info.de@spradling.eu"
  },
  {
    country: defineMessages({
      christmasIsland: {
        id: "countryField.christmasIsland",
        description: "CHRISTMAS ISLAND country option of counry field",
        defaultMessage: "CHRISTMAS ISLAND".toLowerCase()
      }
    }).christmasIsland, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      cocosIslands: {
        id: "countryField.cocosIslands",
        description: "COCOS (KEELING) ISLANDS country option of counry field",
        defaultMessage: "COCOS (KEELING) ISLANDS".toLowerCase()
      }
    }).cocosIslands, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      colombia: {
        id: "countryField.colombia",
        description: "COLOMBIA country option of counry field",
        defaultMessage: "COLOMBIA".toLowerCase()
      }
    }).colombia, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      comoros: {
        id: "countryField.comoros",
        description: "COMOROS country option of counry field",
        defaultMessage: "COMOROS".toLowerCase()
      }
    }).comoros, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      congoDemocraticRepublicOfZaire: {
        id: "countryField.congoDemocraticRepublicOfZaire",
        description: "CONGO, DEMOCRATIC REPUBLIC OF (WAS ZAIRE) country option of counry field",
        defaultMessage: "CONGO, DEMOCRATIC REPUBLIC OF (WAS ZAIRE)".toLowerCase()
      }
    }).congoDemocraticRepublicOfZaire, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      congoPeoplesRepublicOf: {
        id: "countryField.congoPeoplesRepublicOf",
        description: "CONGO, PEOPLE'S REPUBLIC OF country option of counry field",
        defaultMessage: "CONGO, PEOPLE'S REPUBLIC OF".toLowerCase()
      }
    }).congoPeoplesRepublicOf, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      cookIslands: {
        id: "countryField.cookIslands",
        description: "COOK ISLANDS country option of counry field",
        defaultMessage: "COOK ISLANDS".toLowerCase()
      }
    }).cookIslands, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      costaRica: {
        id: "countryField.costaRica",
        description: "COSTA RICA country option of counry field",
        defaultMessage: "COSTA RICA".toLowerCase()
      }
    }).costaRica, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      coteRivoire: {
        id: "countryField.coteRivoire",
        description: "COTE D'IVOIRE country option of counry field",
        defaultMessage: "COTE D'IVOIRE".toLowerCase()
      }
    }).coteRivoire, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      croatia: {
        id: "countryField.croatia",
        description: "CROATIA (HRVATSKA) country option of counry field",
        defaultMessage: "CROATIA (HRVATSKA)".toLowerCase()
      }
    }).croatia, recipient: "info.de@spradling.eu"
  },
  {
    country: defineMessages({
      cuba: {
        id: "countryField.cuba",
        description: "CUBA country option of counry field",
        defaultMessage: "CUBA".toLowerCase()
      }
    }).cuba, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      cyprus: {
        id: "countryField.cyprus",
        description: "CYPRUS country option of counry field",
        defaultMessage: "CYPRUS".toLowerCase()
      }
    }).cyprus, recipient: "info.de@spradling.eu"
  },
  {
    country: defineMessages({
      czechRepublic: {
        id: "countryField.czechRepublic",
        description: "CZECH REPUBLIC country option of counry field",
        defaultMessage: "CZECH REPUBLIC".toLowerCase()
      }
    }).czechRepublic, recipient: "info.de@spradling.eu"
  },
  {
    country: defineMessages({
      denmark: {
        id: "countryField.denmark",
        description: "DENMARK country option of counry field",
        defaultMessage: "DENMARK".toLowerCase()
      }
    }).denmark, recipient: "info.de@spradling.eu"
  },
  {
    country: defineMessages({
      djibouti: {
        id: "countryField.djibouti",
        description: "DJIBOUTI country option of counry field",
        defaultMessage: "DJIBOUTI".toLowerCase()
      }
    }).djibouti, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      dominica: {
        id: "countryField.dominica",
        description: "DOMINICA country option of counry field",
        defaultMessage: "DOMINICA".toLowerCase()
      }
    }).dominica, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      dominicanRepublic: {
        id: "countryField.dominicanRepublic",
        description: "DOMINICAN REPUBLIC country option of counry field",
        defaultMessage: "DOMINICAN REPUBLIC".toLowerCase()
      }
    }).dominicanRepublic, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      eastTimor: {
        id: "countryField.eastTimor",
        description: "EAST TIMOR country option of counry field",
        defaultMessage: "EAST TIMOR".toLowerCase()
      }
    }).eastTimor, recipient: "info.de@spradling.eu"
  },
  {
    country: defineMessages({
      ecuador: {
        id: "countryField.ecuador",
        description: "ECUADOR country option of counry field",
        defaultMessage: "ECUADOR".toLowerCase()
      }
    }).ecuador, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      egypt: {
        id: "countryField.egypt",
        description: "EGYPT country option of counry field",
        defaultMessage: "EGYPT".toLowerCase()
      }
    }).egypt, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      elSalvador: {
        id: "countryField.elSalvador",
        description: "EL SALVADOR country option of counry field",
        defaultMessage: "EL SALVADOR".toLowerCase()
      }
    }).elSalvador, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      equatorialGuinea: {
        id: "countryField.equatorialGuinea",
        description: "EQUATORIAL GUINEA country option of counry field",
        defaultMessage: "EQUATORIAL GUINEA".toLowerCase()
      }
    }).equatorialGuinea, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      eritrea: {
        id: "countryField.eritrea",
        description: "ERITREA country option of counry field",
        defaultMessage: "ERITREA".toLowerCase()
      }
    }).eritrea, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      estonia: {
        id: "countryField.estonia",
        description: "ESTONIA country option of counry field",
        defaultMessage: "ESTONIA".toLowerCase()
      }
    }).estonia, recipient: "info.de@spradling.eu"
  },
  {
    country: defineMessages({
      ethiopia: {
        id: "countryField.ethiopia",
        description: "ETHIOPIA country option of counry field",
        defaultMessage: "ETHIOPIA".toLowerCase()
      }
    }).ethiopia, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      falklandIslandsMalvinas: {
        id: "countryField.falklandIslandsMalvinas",
        description: "FALKLAND ISLANDS (MALVINAS) country option of counry field",
        defaultMessage: "FALKLAND ISLANDS (MALVINAS)".toLowerCase()
      }
    }).falklandIslandsMalvinas, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      faroeIslands: {
        id: "countryField.faroeIslands",
        description: "FAROE ISLANDS country option of counry field",
        defaultMessage: "FAROE ISLANDS".toLowerCase()
      }
    }).faroeIslands, recipient: "info.de@spradling.eu"
  },
  {
    country: defineMessages({
      fiji: {
        id: "countryField.fiji",
        description: "FIJI country option of counry field",
        defaultMessage: "FIJI".toLowerCase()
      }
    }).fiji, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      finland: {
        id: "countryField.finland",
        description: "FINLAND country option of counry field",
        defaultMessage: "FINLAND".toLowerCase()
      }
    }).finland, recipient: "info.de@spradling.eu"
  },
  {
    country: defineMessages({
      france: {
        id: "countryField.france",
        description: "FRANCE country option of counry field",
        defaultMessage: "FRANCE".toLowerCase()
      }
    }).france, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      franceMetropolitan: {
        id: "countryField.franceMetropolitan",
        description: "FRANCE, METROPOLITAN country option of counry field",
        defaultMessage: "FRANCE, METROPOLITAN".toLowerCase()
      }
    }).franceMetropolitan, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      frenchGuiana: {
        id: "countryField.frenchGuiana",
        description: "FRENCH GUIANA country option of counry field",
        defaultMessage: "FRENCH GUIANA".toLowerCase()
      }
    }).frenchGuiana, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      frenchPolynesia: {
        id: "countryField.frenchPolynesia",
        description: "FRENCH POLYNESIA country option of counry field",
        defaultMessage: "FRENCH POLYNESIA".toLowerCase()
      }
    }).frenchPolynesia, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      frenchSouthernTerritories: {
        id: "countryField.frenchSouthernTerritories",
        description: "FRENCH SOUTHERN TERRITORIES country option of counry field",
        defaultMessage: "FRENCH SOUTHERN TERRITORIES".toLowerCase()
      }
    }).frenchSouthernTerritories, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      gabon: {
        id: "countryField.gabon",
        description: "GABON country option of counry field",
        defaultMessage: "GABON".toLowerCase()
      }
    }).gabon, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      gambia: {
        id: "countryField.gambia",
        description: "GAMBIA country option of counry field",
        defaultMessage: "GAMBIA".toLowerCase()
      }
    }).gambia, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      georgia: {
        id: "countryField.georgia",
        description: "GEORGIA country option of counry field",
        defaultMessage: "GEORGIA".toLowerCase()
      }
    }).georgia, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      germany: {
        id: "countryField.germany",
        description: "GERMANY country option of counry field",
        defaultMessage: "GERMANY".toLowerCase()
      }
    }).germany, recipient: "info.de@spradling.eu"
  },
  {
    country: defineMessages({
      ghana: {
        id: "countryField.ghana",
        description: "GHANA country option of counry field",
        defaultMessage: "GHANA".toLowerCase()
      }
    }).ghana, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      gibraltar: {
        id: "countryField.gibraltar",
        description: "GIBRALTAR country option of counry field",
        defaultMessage: "GIBRALTAR".toLowerCase()
      }
    }).gibraltar, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      greece: {
        id: "countryField.greece",
        description: "GREECE country option of counry field",
        defaultMessage: "GREECE".toLowerCase()
      }
    }).greece, recipient: "info.de@spradling.eu"
  },
  {
    country: defineMessages({
      greenland: {
        id: "countryField.greenland",
        description: "GREENLAND country option of counry field",
        defaultMessage: "GREENLAND".toLowerCase()
      }
    }).greenland, recipient: "info.de@spradling.eu"
  },
  {
    country: defineMessages({
      grenada: {
        id: "countryField.grenada",
        description: "GRENADA country option of counry field",
        defaultMessage: "GRENADA".toLowerCase()
      }
    }).grenada, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      guadeloupe: {
        id: "countryField.guadeloupe",
        description: "GUADELOUPE country option of counry field",
        defaultMessage: "GUADELOUPE".toLowerCase()
      }
    }).guadeloupe, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      guam: {
        id: "countryField.guam",
        description: "GUAM country option of counry field",
        defaultMessage: "GUAM".toLowerCase()
      }
    }).guam, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      guatemala: {
        id: "countryField.guatemala",
        description: "GUATEMALA country option of counry field",
        defaultMessage: "GUATEMALA".toLowerCase()
      }
    }).guatemala, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      guinea: {
        id: "countryField.guinea",
        description: "GUINEA country option of counry field",
        defaultMessage: "GUINEA".toLowerCase()
      }
    }).guinea, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      guineaBissau: {
        id: "countryField.guineaBissau",
        description: "GUINEA-BISSAU country option of counry field",
        defaultMessage: "GUINEA-BISSAU".toLowerCase()
      }
    }).guineaBissau, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      guyana: {
        id: "countryField.guyana",
        description: "GUYANA country option of counry field",
        defaultMessage: "GUYANA".toLowerCase()
      }
    }).guyana, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      haiti: {
        id: "countryField.haiti",
        description: "HAITI country option of counry field",
        defaultMessage: "HAITI".toLowerCase()
      }
    }).haiti, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      heardAndMcDonaldIslands: {
        id: "countryField.heardAndMcDonaldIslands",
        description: "HEARD AND MC DONALD ISLANDS country option of counry field",
        defaultMessage: "HEARD AND MC DONALD ISLANDS".toLowerCase()
      }
    }).heardAndMcDonaldIslands, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      honduras: {
        id: "countryField.honduras",
        description: "HONDURAS country option of counry field",
        defaultMessage: "HONDURAS".toLowerCase()
      }
    }).honduras, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      hongKong: {
        id: "countryField.hongKong",
        description: "HONG KONG country option of counry field",
        defaultMessage: "HONG KONG".toLowerCase()
      }
    }).hongKong, recipient: "info.de@spradling.eu"
  },
  {
    country: defineMessages({
      hungary: {
        id: "countryField.hungary",
        description: "HUNGARY country option of counry field",
        defaultMessage: "HUNGARY".toLowerCase()
      }
    }).hungary, recipient: "info.de@spradling.eu"
  },
  {
    country: defineMessages({
      iceland: {
        id: "countryField.iceland",
        description: "ICELAND country option of counry field",
        defaultMessage: "ICELAND".toLowerCase()
      }
    }).iceland, recipient: "info.de@spradling.eu"
  },
  {
    country: defineMessages({
      india: {
        id: "countryField.india",
        description: "INDIA country option of counry field",
        defaultMessage: "INDIA".toLowerCase()
      }
    }).india, recipient: "info.de@spradling.eu"
  },
  {
    country: defineMessages({
      indonesia: {
        id: "countryField.indonesia",
        description: "INDONESIA country option of counry field",
        defaultMessage: "INDONESIA".toLowerCase()
      }
    }).indonesia, recipient: "info.de@spradling.eu"
  },
  {
    country: defineMessages({
      iran: {
        id: "countryField.iran",
        description: "IRAN (ISLAMIC REPUBLIC OF) country option of counry field",
        defaultMessage: "IRAN (ISLAMIC REPUBLIC OF)".toLowerCase()
      }
    }).iran, recipient: "info.de@spradling.eu"
  },
  {
    country: defineMessages({
      iraq: {
        id: "countryField.iraq",
        description: "IRAQ country option of counry field",
        defaultMessage: "IRAQ".toLowerCase()
      }
    }).iraq, recipient: "info.de@spradling.eu"
  },
  {
    country: defineMessages({
      ireland: {
        id: "countryField.ireland",
        description: "IRELAND country option of counry field",
        defaultMessage: "IRELAND".toLowerCase()
      }
    }).ireland, recipient: "info.de@spradling.eu"
  },
  {
    country: defineMessages({
      israel: {
        id: "countryField.israel",
        description: "ISRAEL country option of counry field",
        defaultMessage: "ISRAEL".toLowerCase()
      }
    }).israel, recipient: "info.de@spradling.eu"
  },
  {
    country: defineMessages({
      italy: {
        id: "countryField.italy",
        description: "ITALY country option of counry field",
        defaultMessage: "ITALY".toLowerCase()
      }
    }).italy, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      jamaica: {
        id: "countryField.jamaica",
        description: "JAMAICA country option of counry field",
        defaultMessage: "JAMAICA".toLowerCase()
      }
    }).jamaica, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      japan: {
        id: "countryField.japan",
        description: "JAPAN country option of counry field",
        defaultMessage: "JAPAN".toLowerCase()
      }
    }).japan, recipient: "info.de@spradling.eu"
  },
  {
    country: defineMessages({
      jordan: {
        id: "countryField.jordan",
        description: "JORDAN country option of counry field",
        defaultMessage: "JORDAN".toLowerCase()
      }
    }).jordan, recipient: "info.de@spradling.eu"
  },
  {
    country: defineMessages({
      kazakhstan: {
        id: "countryField.kazakhstan",
        description: "KAZAKHSTAN country option of counry field",
        defaultMessage: "KAZAKHSTAN".toLowerCase()
      }
    }).kazakhstan, recipient: "info.de@spradling.eu"
  },
  {
    country: defineMessages({
      kenya: {
        id: "countryField.kenya",
        description: "KENYA country option of counry field",
        defaultMessage: "KENYA".toLowerCase()
      }
    }).kenya, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      kiribati: {
        id: "countryField.kiribati",
        description: "KIRIBATI country option of counry field",
        defaultMessage: "KIRIBATI".toLowerCase()
      }
    }).kiribati, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      koreaDemocraticPeopleRepublicOf: {
        id: "countryField.koreaDemocraticPeopleRepublicOf",
        description: "KOREA, DEMOCRATIC PEOPLE'S REPUBLIC OF country option of counry field",
        defaultMessage: "KOREA, DEMOCRATIC PEOPLE'S REPUBLIC OF".toLowerCase()
      }
    }).koreaDemocraticPeopleRepublicOf, recipient: "info.de@spradling.eu"
  },
  {
    country: defineMessages({
      koreaRepublicOf: {
        id: "countryField.koreaRepublicOf",
        description: "KOREA, REPUBLIC OF country option of counry field",
        defaultMessage: "KOREA, REPUBLIC OF".toLowerCase()
      }
    }).koreaRepublicOf, recipient: "info.de@spradling.eu"
  },
  {
    country: defineMessages({
      kuwait: {
        id: "countryField.kuwait",
        description: "KUWAIT country option of counry field",
        defaultMessage: "KUWAIT".toLowerCase()
      }
    }).kuwait, recipient: "info.de@spradling.eu"
  },
  {
    country: defineMessages({
      kyrgyzstan: {
        id: "countryField.kyrgyzstan",
        description: "KYRGYZSTAN country option of counry field",
        defaultMessage: "KYRGYZSTAN".toLowerCase()
      }
    }).kyrgyzstan, recipient: "info.de@spradling.eu"
  },
  {
    country: defineMessages({
      laoPeopleDemocraticRepublic: {
        id: "countryField.laoPeopleDemocraticRepublic",
        description: "LAO PEOPLE'S DEMOCRATIC REPUBLIC country option of counry field",
        defaultMessage: "LAO PEOPLE'S DEMOCRATIC REPUBLIC".toLowerCase()
      }
    }).laoPeopleDemocraticRepublic, recipient: "info.de@spradling.eu"
  },
  {
    country: defineMessages({
      latvia: {
        id: "countryField.latvia",
        description: "LATVIA country option of counry field",
        defaultMessage: "LATVIA".toLowerCase()
      }
    }).latvia, recipient: "info.de@spradling.eu"
  },
  {
    country: defineMessages({
      lebanon: {
        id: "countryField.lebanon",
        description: "LEBANON country option of counry field",
        defaultMessage: "LEBANON".toLowerCase()
      }
    }).lebanon, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      lesotho: {
        id: "countryField.lesotho",
        description: "LESOTHO country option of counry field",
        defaultMessage: "LESOTHO".toLowerCase()
      }
    }).lesotho, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      liberia: {
        id: "countryField.liberia",
        description: "LIBERIA country option of counry field",
        defaultMessage: "LIBERIA".toLowerCase()
      }
    }).liberia, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      libyanArabJamahiriya: {
        id: "countryField.libyanArabJamahiriya",
        description: "LIBYAN ARAB JAMAHIRIYA country option of counry field",
        defaultMessage: "LIBYAN ARAB JAMAHIRIYA".toLowerCase()
      }
    }).libyanArabJamahiriya, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      liechtenstein: {
        id: "countryField.liechtenstein",
        description: "LIECHTENSTEIN country option of counry field",
        defaultMessage: "LIECHTENSTEIN".toLowerCase()
      }
    }).liechtenstein, recipient: "info.de@spradling.eu"
  },
  {
    country: defineMessages({
      lithuania: {
        id: "countryField.lithuania",
        description: "LITHUANIA country option of counry field",
        defaultMessage: "LITHUANIA".toLowerCase()
      }
    }).lithuania, recipient: "info.de@spradling.eu"
  },
  {
    country: defineMessages({
      luxembourg: {
        id: "countryField.luxembourg",
        description: "LUXEMBOURG country option of counry field",
        defaultMessage: "LUXEMBOURG".toLowerCase()
      }
    }).luxembourg, recipient: "info.de@spradling.eu"
  },
  {
    country: defineMessages({
      macau: {
        id: "countryField.macau",
        description: "MACAU country option of counry field",
        defaultMessage: "MACAU".toLowerCase()
      }
    }).macau, recipient: "info.de@spradling.eu"
  },
  {
    country: defineMessages({
      macedoniaTheFormerYugoslavRepublicOf: {
        id: "countryField.macedoniaTheFormerYugoslavRepublicOf",
        description: "MACEDONIA, THE FORMER YUGOSLAV REPUBLIC OF country option of counry field",
        defaultMessage: "MACEDONIA, THE FORMER YUGOSLAV REPUBLIC OF".toLowerCase()
      }
    }).macedoniaTheFormerYugoslavRepublicOf, recipient: "info.de@spradling.eu"
  },
  {
    country: defineMessages({
      madagascar: {
        id: "countryField.madagascar",
        description: "MADAGASCAR country option of counry field",
        defaultMessage: "MADAGASCAR".toLowerCase()
      }
    }).madagascar, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      malawi: {
        id: "countryField.malawi",
        description: "MALAWI country option of counry field",
        defaultMessage: "MALAWI".toLowerCase()
      }
    }).malawi, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      malaysia: {
        id: "countryField.malaysia",
        description: "MALAYSIA country option of counry field",
        defaultMessage: "MALAYSIA".toLowerCase()
      }
    }).malaysia, recipient: "info.de@spradling.eu"
  },
  {
    country: defineMessages({
      maldives: {
        id: "countryField.maldives",
        description: "MALDIVES country option of counry field",
        defaultMessage: "MALDIVES".toLowerCase()
      }
    }).maldives, recipient: "info.de@spradling.eu"
  },
  {
    country: defineMessages({
      mali: {
        id: "countryField.mali",
        description: "MALI country option of counry field",
        defaultMessage: "MALI".toLowerCase()
      }
    }).mali, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      malta: {
        id: "countryField.malta",
        description: "MALTA country option of counry field",
        defaultMessage: "MALTA".toLowerCase()
      }
    }).malta, recipient: "info.de@spradling.eu"
  },
  {
    country: defineMessages({
      marshallIslands: {
        id: "countryField.marshallIslands",
        description: "MARSHALL ISLANDS country option of counry field",
        defaultMessage: "MARSHALL ISLANDS".toLowerCase()
      }
    }).marshallIslands, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      martinique: {
        id: "countryField.martinique",
        description: "MARTINIQUE country option of counry field",
        defaultMessage: "MARTINIQUE".toLowerCase()
      }
    }).martinique, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      mauritania: {
        id: "countryField.mauritania",
        description: "MAURITANIA country option of counry field",
        defaultMessage: "MAURITANIA".toLowerCase()
      }
    }).mauritania, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      mauritius: {
        id: "countryField.mauritius",
        description: "MAURITIUS country option of counry field",
        defaultMessage: "MAURITIUS".toLowerCase()
      }
    }).mauritius, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      mayotte: {
        id: "countryField.mayotte",
        description: "MAYOTTE country option of counry field",
        defaultMessage: "MAYOTTE".toLowerCase()
      }
    }).mayotte, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      mexico: {
        id: "countryField.mexico",
        description: "MEXICO country option of counry field",
        defaultMessage: "MEXICO".toLowerCase()
      }
    }).mexico, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      micronesiaFederatedStatesOf: {
        id: "countryField.micronesiaFederatedStatesOf",
        description: "MICRONESIA, FEDERATED STATES OF country option of counry field",
        defaultMessage: "MICRONESIA, FEDERATED STATES OF".toLowerCase()
      }
    }).micronesiaFederatedStatesOf, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      moldovaRepublicOf: {
        id: "countryField.moldovaRepublicOf",
        description: "MOLDOVA, REPUBLIC OF country option of counry field",
        defaultMessage: "MOLDOVA, REPUBLIC OF".toLowerCase()
      }
    }).moldovaRepublicOf, recipient: "info.de@spradling.eu"
  },
  {
    country: defineMessages({
      monaco: {
        id: "countryField.monaco",
        description: "MONACO country option of counry field",
        defaultMessage: "MONACO".toLowerCase()
      }
    }).monaco, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      mongolia: {
        id: "countryField.mongolia",
        description: "MONGOLIA country option of counry field",
        defaultMessage: "MONGOLIA".toLowerCase()
      }
    }).mongolia, recipient: "info.de@spradling.eu"
  },
  {
    country: defineMessages({
      montserrat: {
        id: "countryField.montserrat",
        description: "MONTSERRAT country option of counry field",
        defaultMessage: "MONTSERRAT".toLowerCase()
      }
    }).montserrat, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      morocco: {
        id: "countryField.morocco",
        description: "MOROCCO country option of counry field",
        defaultMessage: "MOROCCO".toLowerCase()
      }
    }).morocco, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      mozambique: {
        id: "countryField.mozambique",
        description: "MOZAMBIQUE country option of counry field",
        defaultMessage: "MOZAMBIQUE".toLowerCase()
      }
    }).mozambique, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      myanmar: {
        id: "countryField.myanmar",
        description: "MYANMAR country option of counry field",
        defaultMessage: "MYANMAR".toLowerCase()
      }
    }).myanmar, recipient: "info.de@spradling.eu"
  },
  {
    country: defineMessages({
      namibia: {
        id: "countryField.namibia",
        description: "NAMIBIA country option of counry field",
        defaultMessage: "NAMIBIA".toLowerCase()
      }
    }).namibia, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      nauru: {
        id: "countryField.nauru",
        description: "NAURU country option of counry field",
        defaultMessage: "NAURU".toLowerCase()
      }
    }).nauru, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      nepal: {
        id: "countryField.nepal",
        description: "NEPAL country option of counry field",
        defaultMessage: "NEPAL".toLowerCase()
      }
    }).nepal, recipient: "info.de@spradling.eu"
  },
  {
    country: defineMessages({
      netherlands: {
        id: "countryField.netherlands",
        description: "NETHERLANDS country option of counry field",
        defaultMessage: "NETHERLANDS".toLowerCase()
      }
    }).netherlands, recipient: "info.de@spradling.eu"
  },
  {
    country: defineMessages({
      netherlandsAntilles: {
        id: "countryField.netherlandsAntilles",
        description: "NETHERLANDS ANTILLES country option of counry field",
        defaultMessage: "NETHERLANDS ANTILLES".toLowerCase()
      }
    }).netherlandsAntilles, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      newCaledonia: {
        id: "countryField.newCaledonia",
        description: "NEW CALEDONIA country option of counry field",
        defaultMessage: "NEW CALEDONIA".toLowerCase()
      }
    }).newCaledonia, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      newZealand: {
        id: "countryField.newZealand",
        description: "NEW ZEALAND country option of counry field",
        defaultMessage: "NEW ZEALAND".toLowerCase()
      }
    }).newZealand, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      nicaragua: {
        id: "countryField.nicaragua",
        description: "NICARAGUA country option of counry field",
        defaultMessage: "NICARAGUA".toLowerCase()
      }
    }).nicaragua, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      niger: {
        id: "countryField.niger",
        description: "NIGER country option of counry field",
        defaultMessage: "NIGER".toLowerCase()
      }
    }).niger, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      nigeria: {
        id: "countryField.nigeria",
        description: "NIGERIA country option of counry field",
        defaultMessage: "NIGERIA".toLowerCase()
      }
    }).nigeria, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      niue: {
        id: "countryField.niue",
        description: "NIUE country option of counry field",
        defaultMessage: "NIUE".toLowerCase()
      }
    }).niue, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      norfolkIsland: {
        id: "countryField.norfolkIsland",
        description: "NORFOLK ISLAND country option of counry field",
        defaultMessage: "NORFOLK ISLAND".toLowerCase()
      }
    }).norfolkIsland, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      northernMarianaIslands: {
        id: "countryField.northernMarianaIslands",
        description: "NORTHERN MARIANA ISLANDS country option of counry field",
        defaultMessage: "NORTHERN MARIANA ISLANDS".toLowerCase()
      }
    }).northernMarianaIslands, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      norway: {
        id: "countryField.norway",
        description: "NORWAY country option of counry field",
        defaultMessage: "NORWAY".toLowerCase()
      }
    }).norway, recipient: "info.de@spradling.eu"
  },
  {
    country: defineMessages({
      oman: {
        id: "countryField.oman",
        description: "OMAN country option of counry field",
        defaultMessage: "OMAN".toLowerCase()
      }
    }).oman, recipient: "info.de@spradling.eu"
  },
  {
    country: defineMessages({
      pakistan: {
        id: "countryField.pakistan",
        description: "PAKISTAN country option of counry field",
        defaultMessage: "PAKISTAN".toLowerCase()
      }
    }).pakistan, recipient: "info.de@spradling.eu"
  },
  {
    country: defineMessages({
      palau: {
        id: "countryField.palau",
        description: "PALAU country option of counry field",
        defaultMessage: "PALAU".toLowerCase()
      }
    }).palau, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      panama: {
        id: "countryField.panama",
        description: "PANAMA country option of counry field",
        defaultMessage: "PANAMA".toLowerCase()
      }
    }).panama, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      papuaNewGuinea: {
        id: "countryField.papuaNewGuinea",
        description: "PAPUA NEW GUINEA country option of counry field",
        defaultMessage: "PAPUA NEW GUINEA".toLowerCase()
      }
    }).papuaNewGuinea, recipient: "info.de@spradling.eu"
  },
  {
    country: defineMessages({
      paraguay: {
        id: "countryField.paraguay",
        description: "PARAGUAY country option of counry field",
        defaultMessage: "PARAGUAY".toLowerCase()
      }
    }).paraguay, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      peru: {
        id: "countryField.peru",
        description: "PERU country option of counry field",
        defaultMessage: "PERU".toLowerCase()
      }
    }).peru, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      philippines: {
        id: "countryField.philippines",
        description: "PHILIPPINES country option of counry field",
        defaultMessage: "PHILIPPINES".toLowerCase()
      }
    }).philippines, recipient: "info.de@spradling.eu"
  },
  {
    country: defineMessages({
      pitcairn: {
        id: "countryField.pitcairn",
        description: "PITCAIRN country option of counry field",
        defaultMessage: "PITCAIRN".toLowerCase()
      }
    }).pitcairn, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      poland: {
        id: "countryField.poland",
        description: "POLAND country option of counry field",
        defaultMessage: "POLAND".toLowerCase()
      }
    }).poland, recipient: "info.de@spradling.eu"
  },
  {
    country: defineMessages({
      portugal: {
        id: "countryField.portugal",
        description: "PORTUGAL country option of counry field",
        defaultMessage: "PORTUGAL".toLowerCase()
      }
    }).portugal, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      puertoRico: {
        id: "countryField.puertoRico",
        description: "PUERTO RICO country option of counry field",
        defaultMessage: "PUERTO RICO".toLowerCase()
      }
    }).puertoRico, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      qatar: {
        id: "countryField.qatar",
        description: "QATAR country option of counry field",
        defaultMessage: "QATAR".toLowerCase()
      }
    }).qatar, recipient: "info.de@spradling.eu"
  },
  {
    country: defineMessages({
      reunion: {
        id: "countryField.reunion",
        description: "REUNION country option of counry field",
        defaultMessage: "REUNION".toLowerCase()
      }
    }).reunion, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      romania: {
        id: "countryField.romania",
        description: "ROMANIA country option of counry field",
        defaultMessage: "ROMANIA".toLowerCase()
      }
    }).romania, recipient: "info.de@spradling.eu"
  },
  {
    country: defineMessages({
      russianFederation: {
        id: "countryField.russianFederation",
        description: "RUSSIAN FEDERATION country option of counry field",
        defaultMessage: "RUSSIAN FEDERATION".toLowerCase()
      }
    }).russianFederation, recipient: "info.de@spradling.eu"
  },
  {
    country: defineMessages({
      rwanda: {
        id: "countryField.rwanda",
        description: "RWANDA country option of counry field",
        defaultMessage: "RWANDA".toLowerCase()
      }
    }).rwanda, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      saintKittsAndNevis: {
        id: "countryField.saintKittsAndNevis",
        description: "SAINT KITTS AND NEVIS country option of counry field",
        defaultMessage: "SAINT KITTS AND NEVIS".toLowerCase()
      }
    }).saintKittsAndNevis, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      saintLucia: {
        id: "countryField.saintLucia",
        description: "SAINT LUCIA country option of counry field",
        defaultMessage: "SAINT LUCIA".toLowerCase()
      }
    }).saintLucia, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      saintVincentAndTheGrenadines: {
        id: "countryField.saintVincentAndTheGrenadines",
        description: "SAINT VINCENT AND THE GRENADINES country option of counry field",
        defaultMessage: "SAINT VINCENT AND THE GRENADINES".toLowerCase()
      }
    }).saintVincentAndTheGrenadines, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      samoa: {
        id: "countryField.samoa",
        description: "SAMOA country option of counry field",
        defaultMessage: "SAMOA".toLowerCase()
      }
    }).samoa, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      san : {
        id: "countryField.san marino",
        description: "SAN MARINO country option of counry field",
        defaultMessage: "SAN MARINO".toLowerCase()
      }
    }).san , recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      saoTomeAndPrincipe: {
        id: "countryField.saoTomeAndPrincipe",
        description: "SAO TOME AND PRINCIPE country option of counry field",
        defaultMessage: "SAO TOME AND PRINCIPE".toLowerCase()
      }
    }).saoTomeAndPrincipe, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      saudiArabia: {
        id: "countryField.saudiArabia",
        description: "SAUDI ARABIA country option of counry field",
        defaultMessage: "SAUDI ARABIA".toLowerCase()
      }
    }).saudiArabia, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      senegal: {
        id: "countryField.senegal",
        description: "SENEGAL country option of counry field",
        defaultMessage: "SENEGAL".toLowerCase()
      }
    }).senegal, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      seychelles: {
        id: "countryField.seychelles",
        description: "SEYCHELLES country option of counry field",
        defaultMessage: "SEYCHELLES".toLowerCase()
      }
    }).seychelles, recipient: "info.de@spradling.eu"
  },
  {
    country: defineMessages({
      sierraLeone: {
        id: "countryField.sierraLeone",
        description: "SIERRA LEONE country option of counry field",
        defaultMessage: "SIERRA LEONE".toLowerCase()
      }
    }).sierraLeone, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      singapore: {
        id: "countryField.singapore",
        description: "SINGAPORE country option of counry field",
        defaultMessage: "SINGAPORE".toLowerCase()
      }
    }).singapore, recipient: "info.de@spradling.eu"
  },
  {
    country: defineMessages({
      slovakia: {
        id: "countryField.slovakia",
        description: "SLOVAKIA (SLOVAK REPUBLIC) country option of counry field",
        defaultMessage: "SLOVAKIA (SLOVAK REPUBLIC)".toLowerCase()
      }
    }).slovakia, recipient: "info.de@spradling.eu"
  },
  {
    country: defineMessages({
      slovenia: {
        id: "countryField.slovenia",
        description: "SLOVENIA country option of counry field",
        defaultMessage: "SLOVENIA".toLowerCase()
      }
    }).slovenia, recipient: "info.de@spradling.eu"
  },
  {
    country: defineMessages({
      solomonIslands: {
        id: "countryField.solomonIslands",
        description: "SOLOMON ISLANDS country option of counry field",
        defaultMessage: "SOLOMON ISLANDS".toLowerCase()
      }
    }).solomonIslands, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      somalia: {
        id: "countryField.somalia",
        description: "SOMALIA country option of counry field",
        defaultMessage: "SOMALIA".toLowerCase()
      }
    }).somalia, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      southAfrica: {
        id: "countryField.southAfrica",
        description: "SOUTH AFRICA country option of counry field",
        defaultMessage: "SOUTH AFRICA".toLowerCase()
      }
    }).southAfrica, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      southGeorgiaAndTheSouthSandwichIslands: {
        id: "countryField.southGeorgiaAndTheSouthSandwichIslands",
        description: "SOUTH GEORGIA AND THE SOUTH SANDWICH ISLANDS country option of counry field",
        defaultMessage: "SOUTH GEORGIA AND THE SOUTH SANDWICH ISLANDS".toLowerCase()
      }
    }).southGeorgiaAndTheSouthSandwichIslands, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      spain: {
        id: "countryField.spain",
        description: "SPAIN country option of counry field",
        defaultMessage: "SPAIN".toLowerCase()
      }
    }).spain, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      sriLanka: {
        id: "countryField.sriLanka",
        description: "SRI LANKA country option of counry field",
        defaultMessage: "SRI LANKA".toLowerCase()
      }
    }).sriLanka, recipient: "info.de@spradling.eu"
  },
  {
    country: defineMessages({
      stHelena: {
        id: "countryField.stHelena",
        description: "ST. HELENA country option of counry field",
        defaultMessage: "ST. HELENA".toLowerCase()
      }
    }).stHelena, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      stPierreAndMiquelon: {
        id: "countryField.stPierreAndMiquelon",
        description: "ST. PIERRE AND MIQUELON country option of counry field",
        defaultMessage: "ST. PIERRE AND MIQUELON".toLowerCase()
      }
    }).stPierreAndMiquelon, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      sudan: {
        id: "countryField.sudan",
        description: "SUDAN country option of counry field",
        defaultMessage: "SUDAN".toLowerCase()
      }
    }).sudan, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      suriname: {
        id: "countryField.suriname",
        description: "SURINAME country option of counry field",
        defaultMessage: "SURINAME".toLowerCase()
      }
    }).suriname, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      svalbardAndJanMayenIslands: {
        id: "countryField.svalbardAndJanMayenIslands",
        description: "SVALBARD AND JAN MAYEN ISLANDS country option of counry field",
        defaultMessage: "SVALBARD AND JAN MAYEN ISLANDS".toLowerCase()
      }
    }).svalbardAndJanMayenIslands, recipient: "info.de@spradling.eu"
  },
  {
    country: defineMessages({
      swaziland: {
        id: "countryField.swaziland",
        description: "SWAZILAND country option of counry field",
        defaultMessage: "SWAZILAND".toLowerCase()
      }
    }).swaziland, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      sweden: {
        id: "countryField.sweden",
        description: "SWEDEN country option of counry field",
        defaultMessage: "SWEDEN".toLowerCase()
      }
    }).sweden, recipient: "info.de@spradling.eu"
  },
  {
    country: defineMessages({
      switzerland: {
        id: "countryField.switzerland",
        description: "SWITZERLAND country option of counry field",
        defaultMessage: "SWITZERLAND".toLowerCase()
      }
    }).switzerland, recipient: "info.de@spradling.eu"
  },
  {
    country: defineMessages({
      syrianArabRepublic: {
        id: "countryField.syrianArabRepublic",
        description: "SYRIAN ARAB REPUBLIC country option of counry field",
        defaultMessage: "SYRIAN ARAB REPUBLIC".toLowerCase()
      }
    }).syrianArabRepublic, recipient: "info.de@spradling.eu"
  },
  {
    country: defineMessages({
      taiwan: {
        id: "countryField.taiwan",
        description: "TAIWAN country option of counry field",
        defaultMessage: "TAIWAN".toLowerCase()
      }
    }).taiwan, recipient: "info.de@spradling.eu"
  },
  {
    country: defineMessages({
      tajikistan: {
        id: "countryField.tajikistan",
        description: "TAJIKISTAN country option of counry field",
        defaultMessage: "TAJIKISTAN".toLowerCase()
      }
    }).tajikistan, recipient: "info.de@spradling.eu"
  },
  {
    country: defineMessages({
      tanzaniaUnitedRepublicOf: {
        id: "countryField.tanzaniaUnitedRepublicOf",
        description: "TANZANIA, UNITED REPUBLIC OF country option of counry field",
        defaultMessage: "TANZANIA, UNITED REPUBLIC OF".toLowerCase()
      }
    }).tanzaniaUnitedRepublicOf, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      thailand: {
        id: "countryField.thailand",
        description: "THAILAND country option of counry field",
        defaultMessage: "THAILAND".toLowerCase()
      }
    }).thailand, recipient: "info.de@spradling.eu"
  },
  {
    country: defineMessages({
      togo: {
        id: "countryField.togo",
        description: "TOGO country option of counry field",
        defaultMessage: "TOGO".toLowerCase()
      }
    }).togo, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      tokelau: {
        id: "countryField.tokelau",
        description: "TOKELAU country option of counry field",
        defaultMessage: "TOKELAU".toLowerCase()
      }
    }).tokelau, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      tonga: {
        id: "countryField.tonga",
        description: "TONGA country option of counry field",
        defaultMessage: "TONGA".toLowerCase()
      }
    }).tonga, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      trinidadAndTobago: {
        id: "countryField.trinidadAndTobago",
        description: "TRINIDAD AND TOBAGO country option of counry field",
        defaultMessage: "TRINIDAD AND TOBAGO".toLowerCase()
      }
    }).trinidadAndTobago, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      tunisia: {
        id: "countryField.tunisia",
        description: "TUNISIA country option of counry field",
        defaultMessage: "TUNISIA".toLowerCase()
      }
    }).tunisia, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      turkey: {
        id: "countryField.turkey",
        description: "TURKEY country option of counry field",
        defaultMessage: "TURKEY".toLowerCase()
      }
    }).turkey, recipient: "info.de@spradling.eu"
  },
  {
    country: defineMessages({
      turkmenistan: {
        id: "countryField.turkmenistan",
        description: "TURKMENISTAN country option of counry field",
        defaultMessage: "TURKMENISTAN".toLowerCase()
      }
    }).turkmenistan, recipient: "info.de@spradling.eu"
  },
  {
    country: defineMessages({
      turksAndCaicosIslands: {
        id: "countryField.turksAndCaicosIslands",
        description: "TURKS AND CAICOS ISLANDS country option of counry field",
        defaultMessage: "TURKS AND CAICOS ISLANDS".toLowerCase()
      }
    }).turksAndCaicosIslands, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      tuvalu: {
        id: "countryField.tuvalu",
        description: "TUVALU country option of counry field",
        defaultMessage: "TUVALU".toLowerCase()
      }
    }).tuvalu, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      uganda: {
        id: "countryField.uganda",
        description: "UGANDA country option of counry field",
        defaultMessage: "UGANDA".toLowerCase()
      }
    }).uganda, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      ukraine: {
        id: "countryField.ukraine",
        description: "UKRAINE country option of counry field",
        defaultMessage: "UKRAINE".toLowerCase()
      }
    }).ukraine, recipient: "info.de@spradling.eu"
  },
  {
    country: defineMessages({
      unitedArabEmirates: {
        id: "countryField.unitedArabEmirates",
        description: "UNITED ARAB EMIRATES country option of counry field",
        defaultMessage: "UNITED ARAB EMIRATES".toLowerCase()
      }
    }).unitedArabEmirates, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      unitedKingdom: {
        id: "countryField.unitedKingdom",
        description: "UNITED KINGDOM country option of counry field",
        defaultMessage: "UNITED KINGDOM".toLowerCase()
      }
    }).unitedKingdom, recipient: "info.uk@spradling.eu"
  },
  {
    country: defineMessages({
      unitedStates: {
        id: "countryField.unitedStates",
        description: "UNITED STATES country option of counry field",
        defaultMessage: "UNITED STATES".toLowerCase()
      }
    }).unitedStates, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      unitedStatesMinorOutlyingIslands: {
        id: "countryField.unitedStatesMinorOutlyingIslands",
        description: "UNITED STATES MINOR OUTLYING ISLANDS country option of counry field",
        defaultMessage: "UNITED STATES MINOR OUTLYING ISLANDS".toLowerCase()
      }
    }).unitedStatesMinorOutlyingIslands, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      uruguay: {
        id: "countryField.uruguay",
        description: "URUGUAY country option of counry field",
        defaultMessage: "URUGUAY".toLowerCase()
      }
    }).uruguay, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      uzbekistan: {
        id: "countryField.uzbekistan",
        description: "UZBEKISTAN country option of counry field",
        defaultMessage: "UZBEKISTAN".toLowerCase()
      }
    }).uzbekistan, recipient: "info.de@spradling.eu"
  },
  {
    country: defineMessages({
      vanuatu: {
        id: "countryField.vanuatu",
        description: "VANUATU country option of counry field",
        defaultMessage: "VANUATU".toLowerCase()
      }
    }).vanuatu, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      vaticanCityState: {
        id: "countryField.vaticanCityState",
        description: "VATICAN CITY STATE (HOLY CITY) country option of counry field",
        defaultMessage: "VATICAN CITY STATE (HOLY CITY)".toLowerCase()
      }
    }).vaticanCityState, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      venezuela: {
        id: "countryField.venezuela",
        description: "VENEZUELA country option of counry field",
        defaultMessage: "VENEZUELA".toLowerCase()
      }
    }).venezuela, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      vietnam: {
        id: "countryField.vietnam",
        description: "VIETNAM country option of counry field",
        defaultMessage: "VIETNAM".toLowerCase()
      }
    }).vietnam, recipient: "info.de@spradling.eu"
  },
  {
    country: defineMessages({
      virginIslands: {
        id: "countryField.virginIslandsBritish",
        description: "VIRGIN ISLANDS (BRITISH) country option of counry field",
        defaultMessage: "VIRGIN ISLANDS (BRITISH)".toLowerCase()
      }
    }).virginIslands, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      virginIslands: {
        id: "countryField.virginIslandsUS",
        description: "VIRGIN ISLANDS (U.S.) country option of counry field",
        defaultMessage: "VIRGIN ISLANDS (U.S.)".toLowerCase()
      }
    }).virginIslands, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      wallisAndFutunaIslands: {
        id: "countryField.wallisAndFutunaIslands",
        description: "WALLIS AND FUTUNA ISLANDS country option of counry field",
        defaultMessage: "WALLIS AND FUTUNA ISLANDS".toLowerCase()
      }
    }).wallisAndFutunaIslands, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      yemen: {
        id: "countryField.yemen",
        description: "YEMEN country option of counry field",
        defaultMessage: "YEMEN".toLowerCase()
      }
    }).yemen, recipient: "info.de@spradling.eu"
  },
  {
    country: defineMessages({
      zambia: {
        id: "countryField.zambia",
        description: "ZAMBIA country option of counry field",
        defaultMessage: "ZAMBIA".toLowerCase()
      }
    }).zambia, recipient: "info.es@spradling.eu"
  },
  {
    country: defineMessages({
      zimbabwe: {
        id: "countryField.zimbabwe",
        description: "ZIMBABWE country option of counry field",
        defaultMessage: "ZIMBABWE".toLowerCase()
      }
    }).zimbabwe, recipient: "info.es@spradling.eu"
  },
];
