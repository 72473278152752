import React from "react";
import {Redirect} from "react-router-dom";

const languages = ['fr', 'de', 'en'];

const RedirectToLanguage = (props) => {
  let lang = languages[0];
  const navigatorLanguages = navigator.language.split(/[-_]/);

  if (languages.find(item => item === navigatorLanguages[0]))
    lang = navigatorLanguages[0];

  return (
      <Redirect to={`/${lang}`}/>
  )
};

export default RedirectToLanguage;