import gql from "graphql-tag";

const b1CollectionSubscribeToNewsletterMutation = gql`
    mutation B1CollectionSubscribeToNewsletter($input: B1CollectionSubscribeToNewsletterInput!) {
        b1CollectionSubscribeToNewsletter(input: $input) {
            created
        }
    }
`;

export default b1CollectionSubscribeToNewsletterMutation;