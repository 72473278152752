import gql from "graphql-tag";

const createB1CollectionLeadMutation = gql`
    mutation CreateB1CollectionLead($input: CreateB1CollectionLeadInput!) {
        createB1CollectionLead(input: $input) {
            created
        }
    }
`;

export default createB1CollectionLeadMutation;