import React from "react";
import SpecsCard from "components/Specs/components/SpecsCard";
import {FormattedHTMLMessage, FormattedMessage} from "react-intl";
import firstSpecImg from "./img/1st-spec.jpg";
import secondSpecImg from "./img/2nd-spec.jpg";
import "./styles/Specs.scss";

const Specs = (props) => (
    <section className="specs pt-5 mb-5">
      <div className="container">
        <p className="text-justify mb-5">
          <FormattedHTMLMessage
              id="Specs.prefaceV2"
              description="Preface in the specs section"
              defaultMessage="Afin de satisfaire aux normes non feu sévères M1 et AM 18  de la prescription en France et à la réglementation européenne en matière de résistance au feu,  Spradling<sup>&reg;</sup> présente la collection B1 , adaptée aux normes les plus sévères de l’industrie Allemande B1; 25 coloris en SILVERTEX<sup>&reg;</sup> B1 (SB1-XXXX) ET 24 coloris en VALENCIA™ B1 (VB1-XXXX) sont désormais disponibles dans les gammes réputées que vous connaissez. Nous offrons un aspect toile textile et un look similicuir qui garantiront une performance élevée,  une parfaite combinaison design."/>
        </p>
        <div className="mb-4">
          <div className="certified-box">
            <h1 className="certified-box__title">
              <FormattedMessage
                  id="Specs.certifiedBoxTitle"
                  description="Certified box title"
                  defaultMessage="100% CERTIFIÉ!"/>
            </h1>
            <p className="certified-box__subtitle">
              <FormattedHTMLMessage
                  id="Specs.certifiedBoxSubtitle"
                  description="Certified box subtitle"
                  defaultMessage="Les exigences techniques les plus strictes ont été dépassées pour faire de la collection B1 le choix idéal pour les applications d'intérieurs collectivités et médical."/>
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-lg-6 mb-4 mb-lg-0">
            <SpecsCard
                backgroundImage={firstSpecImg}
                title={
                  <FormattedMessage
                      id="Specs.styleCardTitleV2"
                      description="Title for style card in Specs component"
                      defaultMessage="ASSURER LA QUALITÉ!"/>
                }
                description={
                  <FormattedHTMLMessage
                      id="Specs.styleCardDescriptionV4"
                      description="Description for style card in Specs component"
                      defaultMessage="Silvertex<sup>&reg;</sup> B1 et Valencia™ B1 sont désormais certifiées selon les normes DIN 4102 B1, NF P 92-503 M1 et AM 18, CRIB7, pour répondre aux exigences de revêtements de mobilier selon normes françaises en matière de protection incendie. Grâce à ses propriétés imperméables et faciles à nettoyer, en plus d'être SANS PHTALATE, cette collection de tissus enduits est une solution de haute qualité pour vous apporter les solutions de mobiliers les plus exigeantes, sophistiquées et innovantes."/>
                }/>
          </div>
          <div className="col-12 col-lg-6 mb-4 mb-lg-0">
            <SpecsCard
                backgroundImage={secondSpecImg}
                title={
                  <FormattedMessage
                      id="Specs.beautifulCardTitleV2"
                      description="Title for beautiful card in Specs component"
                      defaultMessage="EXPLOSION DE COULEURS ET TEXTURES"/>
                }
                description={
                  <FormattedHTMLMessage
                      id="Specs.beautifulCardDescriptionV3"
                      description="Description for beautiful card in Specs component"
                      defaultMessage="Dans le but de proposer la combinaison idéale de textures, nous avons trouvé dans nos bestsellers reconnus Valencia™ & Silvertex<sup>&reg;</sup> la combinaison parfaite de tissus enduits pour créer la nouvelle collection B1/M1 que nous vous proposons. Alors que Silvertex<sup>&reg;</sup> B1 apporte un look textile sophistiqué et polyvalent, le Valencia™ B1 ne vous séduira pas son élégance cuir mat et doux de haute performance."/>
                }/>
          </div>
        </div>
      </div>
    </section>
);

export default Specs;